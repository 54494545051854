import { Show } from "@type/shows";

export const getShowProgressBarColor = (show: Show) => {
  const { issued, pending, limit } = show.attendees;
  if (issued + pending > limit) return "error";
  if (show.status === "soldout") return "info";
  if (show.status === "low") return "warning";
  if (show.status === "not_available") return "disabled";
  return "success";
};
