import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  PageLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  useAuth,
} from "@eventsquare/uikit";

import { Pro } from "@components/Pro/Pro";

import { Api } from "@lib/api";

import { Export } from "@type/exports";
import { CreateExportModal } from "@components/modals/CreateExportModal/CreateExportModal";

const Colgroup = () => {
  return (
    <colgroup>
      <col />
      <col />
      <col style={{ width: "9.125rem" }} />
    </colgroup>
  );
};

export const EditionTypesExports = () => {
  const { ticketId } = useParams();
  const { account } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [exports, setExports] = useState<Export[] | undefined>(undefined);
  const [showCreateExportModal, setShowCreateExportModal] =
    useState<boolean>(false);

  const fetchExports = useCallback(async () => {
    try {
      setLoading(true);
      const { exports } = await Api.get(`/types/${ticketId}/exports`);
      setExports(exports);
      console.log(exports);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchExports();
  }, [fetchExports]);

  const downloadExport = async (id: string, type: "csv" | "xls") => {
    // TODO : download export
    console.log(id, type);
  };

  if (account?.plan !== "pro") {
    return (
      <Container>
        <Pro />
      </Container>
    );
  }

  return (
    <>
      <Container>
        {!exports && <PageLoader />}
        {exports && (
          <>
            <div>
              <Text variant="h3">Exports</Text>
              <Text variant="lead">
                Export barcodes from this ticket type to use for hardcopy
                tickets or other purposes. These barcodes can be scanned once,
                just like ordered tickets.
              </Text>
            </div>
            {loading && <PageLoader />}
            {!loading && (
              <>
                {exports && exports.length >= 1 && (
                  <Table scrollable>
                    <Colgroup />
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Reference</TableHeadCell>
                        <TableHeadCell>Number of barcodes</TableHeadCell>
                        <TableHeadCell>Download</TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exports.map((exportItem) => (
                        <TableRow key={exportItem.id}>
                          <TableCell>
                            <strong>{exportItem.reference}</strong>
                          </TableCell>
                          <TableCell>{exportItem.has.tickets}</TableCell>
                          <TableCell>
                            <ButtonGroup noMargin inline>
                              <Button
                                size="tiny"
                                variant="outline"
                                onClick={() =>
                                  downloadExport(exportItem.id, "csv")
                                }
                              >
                                CSV
                              </Button>
                              <Button
                                size="tiny"
                                variant="outline"
                                onClick={() =>
                                  downloadExport(exportItem.id, "xls")
                                }
                              >
                                XLS
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <div>
                  <Button onClick={() => setShowCreateExportModal(true)}>
                    Create export
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Container>
      <CreateExportModal
        ticketId={ticketId!}
        showModal={showCreateExportModal}
        closeModal={() => setShowCreateExportModal(false)}
        afterCreate={() => {
          fetchExports();
          setShowCreateExportModal(false);
        }}
      />
    </>
  );
};
