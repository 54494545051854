import { createContext, useContext } from "react";

import { Show } from "@type/shows";

export interface ShowContextInterface {
  show: Show | null;
  setShow: (show: Show | null) => void;
  fetchShow: () => void;
  scrollUp: () => void;
}

export const ShowContext = createContext<ShowContextInterface | undefined>(
  undefined
);

export const useShowContext = () => {
  return useContext(ShowContext) as ShowContextInterface;
};
