import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  EventCard,
  EventGrid,
  PageHeader,
  PageLoader,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { FilterBar, FilterSelect } from "@eventsquare/uikit/filters";
import { Api } from "@lib/api";
import { formatDate } from "@lib/helpers";

interface Event {
  id: string;
  name: string;
  type: EventTypeType;
  branding: {
    background: {
      color: string;
    };
  };
}

interface Edition {
  id: string;
  name: string | null;
  dates: {
    start: string | null;
    end: string | null;
  };
  branding: {
    banner: string | null;
    color: string | null;
    logo: string | null;
    background: {
      color: string | null;
      image: string | null;
    };
  };
  status: {
    published: boolean;
    live: boolean;
  };
  created_at: string;
}

type EventTypeType =
  | "charity"
  | "comedy"
  | "concert"
  | "conference"
  | "culinary"
  | "dance"
  | "education"
  | "exhibition"
  | "fair"
  | "festival"
  | "film"
  | "health"
  | "lan-party"
  | "leisure"
  | "market"
  | "meeting"
  | "musical"
  | "other"
  | "outdoor"
  | "party"
  | "seminar"
  | "show"
  | "theater"
  | "tournament"
  | "travelling"
  | "workshop";

type Sorting = "alphabetically" | "creation_date" | "event_date" | "status";

// TODO : Move typescript types to shared folder
// TODO : Error handling

export const EventPage = () => {
  const { user } = useAuth();
  const { eventId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<Event | null>(null);
  const [editions, setEditions] = useState<Edition[] | null>(null);
  const [editionsTotal, setEditionsTotal] = useState<number | undefined>(
    undefined
  );
  const [sorting, setSorting] = useState<Sorting>("event_date");

  const fetchEvent = useCallback(async () => {
    setLoading(true);
    try {
      const { event } = await Api.get(`/events/${eventId}`);
      setEvent(event);
      const { editions, total } = await Api.get(`/events/${eventId}/editions`);
      setEditions(editions);
      setEditionsTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  const sortEditions = (editionsArr: Edition[], sortingMethod: Sorting) => {
    switch (sortingMethod) {
      case "alphabetically":
        return editionsArr.sort((a, b) => {
          if (!a.name) return -1;
          if (!b.name) return 1;
          return a.name.trim() < b.name.trim() ? -1 : 1;
        });
      case "creation_date":
        return editionsArr.sort((a, b) =>
          a.created_at > b.created_at ? -1 : 1
        );
      case "event_date": {
        const noDates = editionsArr
          .filter((event) => !event.dates.start)
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
        const futureDates = editionsArr
          .filter(
            (event) =>
              !!event.dates.start && new Date(event.dates.start) > new Date()
          )
          .sort((a, b) => (a.dates.start! < b.dates.start! ? -1 : 1));
        const pastDates = editionsArr
          .filter(
            (event) =>
              !!event.dates.start && new Date(event.dates.start) < new Date()
          )
          .sort((a, b) => (a.dates.start! > b.dates.start! ? -1 : 1));

        return [...futureDates, ...pastDates, ...noDates];
      }
      case "status": {
        const liveStores = editionsArr
          .filter((event) => event.status.live === true)
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });
        const openedStores = editionsArr
          .filter(
            (event) =>
              event.status.published === true && event.status.live === false
          )
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });
        const closedStores = editionsArr
          .filter(
            (event) =>
              event.status.published === false && event.status.live === false
          )
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });

        return [...liveStores, ...openedStores, ...closedStores];
      }
      default:
        return editionsArr;
    }
  };

  return (
    <>
      <UIPage>
        {loading && <PageLoader />}
        {!loading && event && (
          <>
            <PageHeader title={event.name} badge={editionsTotal ?? undefined} />
            <Container>
              <FilterBar fixed>
                <FilterSelect
                  sort
                  value={sorting}
                  onChange={(sort: string) => setSorting(sort as Sorting)}
                  options={[
                    {
                      value: "event_date",
                      title: "Date",
                    },
                    {
                      value: "creation_date",
                      title: "Creation date",
                    },
                    {
                      value: "alphabetically",
                      title: "Name",
                    },
                  ]}
                />
              </FilterBar>
              <EventGrid>
                {editions &&
                  sortEditions(editions, sorting).map((edition, index) => (
                    <Link
                      to={`/events/${event.id}/editions/${edition.id}`}
                      key={edition.id}
                    >
                      <EventCard
                        title={edition.name ?? "First edition"}
                        subtitle={
                          edition.dates.start
                            ? formatDate(edition!.dates!.start, user!.language)
                            : undefined
                        }
                        banner={edition.branding.banner ?? undefined}
                        logo={edition.branding.logo ?? undefined}
                        color={edition.branding.color ?? "#068CFC"}
                        backgroundColor={
                          edition.branding.background.color ??
                          edition.branding.color ??
                          "#068CFC"
                        }
                        type={event.type}
                        status={
                          edition.status.live
                            ? "live"
                            : edition.status.published
                            ? "open"
                            : "closed"
                        }
                        popIn
                        index={index}
                      />
                    </Link>
                  ))}
              </EventGrid>
            </Container>
          </>
        )}
      </UIPage>
    </>
  );
};
