import {
  BackButton,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Empty,
  Message,
  PageHeader,
  PageLoader,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./create.module.scss";
import {
  Form,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
  FormRadioGroup,
  FormSelect,
  FormTextArea,
} from "@eventsquare/uikit/forms";
import { useEffect, useRef, useState } from "react";
import { FormikProps, FormikValues } from "formik";
import {
  RiAddLine,
  RiDeleteBinLine,
  RiSubtractLine,
  RiTicketLine,
} from "@remixicon/react";
import * as yup from "yup";

import { CustomerLanguage } from "@type/languages";
import { InvitationCartItem, InvitationType } from "@type/invitations";

import { Api } from "@lib/api";
import { getLocalStorage, setLocalStorage } from "@lib/localStorage";

import { AddTypeModal } from "@components/modals/AddTypeModal/AddTypeModal";
import { formatPrice, getShowName } from "@lib/helpers";
import { useEditionContext } from "@context/editionContext";
import { Show } from "@type/shows";

interface InvitationCreateValues {
  type: InvitationType;
  invitees: string;
  reference: string;
  language: CustomerLanguage;
  validity_period: string;
  // | "4320"
  // | "10080"
  // | "43800"
  // | "262800"
  // | "525600"
  // | "1576800"
  // | "never";
  message: string;
}

const getInitialValidityPeriod = () => {
  const validityPeriod = getLocalStorage("invitation_validity_period");
  return validityPeriod || "43800";
};

export const EditionInvitationCreate = () => {
  const { eventId, editionId } = useParams();
  const { user } = useAuth();
  const { event } = useEditionContext();
  const navigate = useNavigate();

  const invitationRef = useRef<HTMLDivElement>(null);

  const [cart, setCart] = useState<InvitationCartItem[]>([]);
  const [customerLanguages, setCustomerLanguages] = useState<
    { code: CustomerLanguage }[] | undefined
  >(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [invitationType, setInvitationType] = useState<"free" | "paylink">(
    "free"
  );
  const [sendingInvitation, setSendingInvitation] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[] | undefined>([]);

  const fetchCustomersLanguages = async () => {
    try {
      const { languages } = await Api.get(`/lists/languages`, {
        type: "customers",
      });
      setCustomerLanguages(languages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomersLanguages();
  }, []);

  const initialValues: InvitationCreateValues = {
    type: invitationType,
    invitees: "",
    reference: "",
    language: user?.language || "nl",
    validity_period: getInitialValidityPeriod(),
    message: "",
  };

  const scrollUp = () => {
    if (!invitationRef.current) return;
    invitationRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const parseValidationErrors = (errors: Record<string, string[]>) => {
    // Errors is an object with array inside, i want it flat
    // Loop over all validation error properties
    const validationErrors: string[] = [];
    for (const property in errors) {
      // Replace . with _ in property
      const propertyKey = property.replace(/\./g, "_");
      // Loop over all errors in property array
      errors[property].forEach((error: string) => {
        validationErrors.push(`${propertyKey}_${error}`);
      });
    }
    return validationErrors;
  };

  const handleSubmit = async (values: InvitationCreateValues) => {
    if (sendingInvitation) {
      return;
    }

    setErrors(undefined);

    try {
      setSendingInvitation(true);
      const errorList: string[] = [];

      if (cart.length === 0) {
        errorList.push("empty_cart");
        return;
      }

      const types = cart.map((item) => {
        return {
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          show: item.show?.id ?? null,
        };
      });

      const invitees = values.invitees
        //split the string by comma or space
        .split(/[\s,]+/)
        //filter out empty strings
        .filter((email) => email.trim().length > 0)
        .map((email) => email.trim())
        .map((email) => {
          return {
            email,
            reference: values.reference,
            types: types,
          };
        });

      if (errorList.length > 0) {
        setErrors(errorList);
        return;
      }

      await Api.post(`/editions/${editionId}/invitations`, {
        type: values.type,
        invitees: invitees,
        language: values.language,
        reference: values.reference,
        validity_period: values.validity_period,
        message: values.message,
      });

      setLocalStorage("invitation_validity_period", values.validity_period);
      setSendingInvitation(false);
      navigate(
        `/events/${eventId}/editions/${editionId}/invitations?saved=true`
      );
    } catch (error) {
      // TODO : Error handling
      const data = (
        error as { response: { body: { error: string; validation: unknown } } }
      ).response.body;
      if (data.error === "validation_failed") {
        const errors = parseValidationErrors(
          data.validation as Record<string, string[]>
        );
        setErrors(errors);
      } else {
        setErrors(["something_went_wrong"]);
      }
    } finally {
      setSendingInvitation(false);
      scrollUp();
    }
  };

  const handleAddToCart = (addItem: InvitationCartItem) => {
    const cartArr = [...cart];
    const index = cartArr.findIndex((item) => item.id === addItem.id);

    if (index === -1) {
      cartArr.push(addItem);
    } else {
      cartArr[index].quantity += addItem.quantity;
    }

    setCart(cartArr);
  };

  const emailListValidation = yup
    .string()
    .test("valid-email-list", function (value) {
      if (!value) return true; // Leeg veld wordt door andere regels gecontroleerd, zoals `.required()`

      const emailList = value
        .split(/[\s,]+/) // Splits op komma's of spaties
        .filter((email) => email.trim() !== ""); // Verwijder lege waarden

      // Maak een lijst van ongeldige e-mailadressen
      const invalidEmails = emailList.filter(
        (email) => !yup.string().email().isValidSync(email)
      );

      if (invalidEmails.length > 0) {
        // Stel foutmelding dynamisch samen
        return this.createError({
          message: `Er zijn ${
            invalidEmails.length
          } ongeldige e-mailadressen: ${invalidEmails.join(", ")}`,
        });
      }

      return true; // Geen ongeldige e-mailadressen gevonden
    })
    .required("Please enter at least one email address");

  const yupSchema = yup.object().shape({
    invitees: emailListValidation,
    reference: yup.string().required("Please enter a reference"),
  });

  const setCartQuantity = (id: string, quantity: number) => {
    const newCart = cart.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity,
        };
      }
      return item;
    });

    setCart(newCart);
  };

  // TODO: Test this page !!

  return (
    <UIPage ref={invitationRef}>
      <PageHeader
        title="Create a new invitation"
        topAction={
          <Link to={`/events/${eventId}/editions/${editionId}/invitations`}>
            <BackButton>Back to invitations</BackButton>
          </Link>
        }
      />
      <Container>
        {errors && errors.length > 0 && (
          <Message message={errors} type="error" alert />
        )}
        {!customerLanguages && <PageLoader />}
        {customerLanguages && (
          <div className={styles.createInvitation}>
            <div className={styles.createInvitation__cart}>
              <Section>
                {cart.length === 0 && (
                  <Empty
                    icon={<RiTicketLine />}
                    title="No tickets selected"
                    lead="Select the tickets for your invitation by clicking on the button bellow"
                  >
                    <ButtonGroup noMargin>
                      <Button onClick={() => setShowModal(true)}>
                        Open ticket modal
                      </Button>
                    </ButtonGroup>
                  </Empty>
                )}
                {cart.length > 0 && (
                  <>
                    <div>
                      <Text variant="h3">Cart</Text>
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeadCell>Name</TableHeadCell>
                          <TableHeadCell>Quantity</TableHeadCell>
                          <TableHeadCell>Price</TableHeadCell>
                          <TableHeadCell width="2rem"></TableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cart.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              <div>
                                <strong>{item.name}</strong>
                              </div>
                              {item.show && (
                                <Text variant="small" noMargin>
                                  {getShowName(
                                    item.show as Show,
                                    user!.language
                                  )}
                                </Text>
                              )}
                            </TableCell>
                            <TableCell>
                              <div className={styles.quantity}>
                                <Button
                                  icon
                                  size="tiny"
                                  variant="outline"
                                  disabled={item.quantity === 1}
                                  onClick={() => {
                                    setCartQuantity(item.id, item.quantity - 1);
                                  }}
                                >
                                  <RiSubtractLine />
                                </Button>
                                <input
                                  className={styles.quantity__input}
                                  value={item.quantity}
                                  type="number"
                                  step={1}
                                  min={1}
                                  onChange={(e) => {
                                    setCartQuantity(item.id, +e.target.value);
                                  }}
                                />
                                <Button
                                  icon
                                  size="tiny"
                                  variant="outline"
                                  onClick={() => {
                                    setCartQuantity(item.id, item.quantity + 1);
                                  }}
                                >
                                  <RiAddLine />
                                </Button>
                              </div>
                            </TableCell>
                            <TableCell>
                              {invitationType === "free"
                                ? "Free"
                                : formatPrice(
                                    item.quantity * item.price,
                                    event!.currency,
                                    user!.language
                                  )}
                            </TableCell>
                            <TableCell textAlign="right">
                              <Button
                                icon
                                size="tiny"
                                color="error"
                                variant="outline"
                                onClick={() => {
                                  const newCart = cart.filter(
                                    (cartItem) => cartItem.id !== item.id
                                  );
                                  setCart(newCart);
                                }}
                              >
                                <RiDeleteBinLine />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div>
                      <Button
                        onClick={() => setShowModal(true)}
                        disabled={sendingInvitation}
                      >
                        Add tickets
                      </Button>
                    </div>
                  </>
                )}
              </Section>
            </div>
            <div className={styles.createInvitation__form}>
              <Section boxed>
                <Form
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={yupSchema}
                >
                  {(context) => (
                    <InvitationForm
                      context={context}
                      customerLanguages={customerLanguages}
                      setInvitationType={setInvitationType}
                      cart={cart}
                      sending={sendingInvitation}
                    />
                  )}
                </Form>
              </Section>
            </div>
          </div>
        )}
      </Container>
      <AddTypeModal
        showModal={showModal}
        closeModel={() => setShowModal(false)}
        editionId={editionId!}
        addToCart={handleAddToCart}
        invitationType={invitationType}
      />
    </UIPage>
  );
};

interface InvitationFormProps {
  context: FormikProps<FormikValues>;
  customerLanguages: { code: CustomerLanguage }[];
  setInvitationType: (type: "free" | "paylink") => void;
  cart: InvitationCartItem[];
  sending: boolean;
}

const InvitationForm = (props: InvitationFormProps) => {
  const { customerLanguages, context, setInvitationType, cart, sending } =
    props;

  const { values, setFieldValue } = context;
  useEffect(() => {
    setInvitationType(values.type);
  }, [setInvitationType, values.type]);

  const calculateLength = (invitees: string) => {
    // Split the string by comma or space
    const email = invitees.split(/[\s,]+/);
    // filter out empty strings
    const filteredEmails = email.filter((word) => word.trim().length > 0);
    // return the length of the filtered words
    return filteredEmails.length;
  };

  const handleEmailPaste = async (
    e: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const data =
      e.clipboardData || (window as unknown as ClipboardEvent).clipboardData;
    let text = data.getData("text");

    // first remove all new lines
    if (text.includes("\n")) {
      text = text
        .split("\n")
        .filter((email) => email.trim().length > 0)
        .map((email) => email.trim())
        .join(", ");
    }

    // then handle the comma and space
    if (text.includes(",") || text.includes(" ")) {
      text = text
        .split(/[\s,]+/) // split on comma or space
        .filter((email) => email.trim().length > 0)
        .map((email) => email.trim())
        .join(", ");
    }

    // extends the current value with the new value
    setFieldValue("invitees", values.invitees + text);
  };

  return (
    <>
      <FormFieldset disabled={sending}>
        <FormGroup>
          <FormLabel>Type</FormLabel>
          <FormRadioGroup
            name="type"
            options={[
              { label: "Free", value: "free" },
              { label: "Paylink", value: "paylink" },
            ]}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="invitees" helperText="required">
            <span className={styles.invitees__label}>
              Email addresses{" "}
              {values.invitees.trim().length > 0 && (
                <Badge
                  size="small"
                  content={calculateLength(values.invitees)}
                />
              )}
            </span>
          </FormLabel>
          <FormTextArea name="invitees" rows={4} onPaste={handleEmailPaste} />
          <Text variant="small">
            You can add multiple emails by separating by comma or space
          </Text>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="reference" helperText="required">
            Reference
          </FormLabel>
          <FormInput name="reference" />
          <Text variant="small">The reference is visible to the invitee.</Text>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="validity_period">Expires in</FormLabel>
          <FormSelect
            placeholder="Select an expiration time"
            name="validity_period"
            options={[
              { value: "4320", label: "3 days" },
              { value: "10080", label: "1 week" },
              { value: "43800", label: "1 month" },
              { value: "262800", label: "6 months" },
              { value: "525600", label: "1 year" },
              { value: "1576800", label: "3 years" },
              { value: "never", label: "Never" },
            ]}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="language">Language</FormLabel>
          <FormSelect
            name="language"
            options={customerLanguages.map((lang) => {
              return {
                value: lang.code,
                label: lang.code,
              };
            })}
            placeholder="Select language"
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup>
        <FormLabel htmlFor="message">Message</FormLabel>
        <FormTextArea name="message" rows={4} />
      </FormGroup>
      <div>
        <Button
          type="submit"
          disabled={!cart.length || sending}
          loading={sending}
        >
          Create invitation
        </Button>
      </div>
    </>
  );
};
