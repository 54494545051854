import { createContext, useContext } from "react";

import { TypeDetails } from "@type/types";

export interface TypeContextInterface {
  type: TypeDetails | null;
  setType: (type: TypeDetails | null) => void;
  fetchType: () => void;
  scrollUp: () => void;
}

export const TypeContext = createContext<TypeContextInterface | undefined>(
  undefined
);

export const useTypeContext = () => {
  return useContext(TypeContext) as TypeContextInterface;
};
