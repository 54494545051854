import { Button, Message, Modal, ModalButtons, Text } from "@eventsquare/uikit";
import { useState } from "react";
import { RiDownloadLine } from "@remixicon/react";
import {
  Form,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
} from "@eventsquare/uikit/forms";
import * as yup from "yup";
import { Api } from "@lib/api";

interface CreateExportModalProps {
  ticketId: string;
  showModal: boolean;
  closeModal: () => void;
  afterCreate: () => void;
}

interface CreateExportValues {
  reference: string;
  quantity: number;
}

export const CreateExportModal = (props: CreateExportModalProps) => {
  const { ticketId, showModal, closeModal, afterCreate } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initialValues: CreateExportValues = { reference: "", quantity: 100 };

  const yupSchema = yup.object().shape({
    reference: yup.string().required("Please enter a reference"),
    quantity: yup
      .number()
      .integer()
      .required("Please enter a quantity")
      .min(1, "Your export should have at least 1 code")
      .max(10000, "Your export cannot have more than 10000 codes"),
  });

  const handleSubmit = async (values: CreateExportValues) => {
    setLoading(true);
    setError(null);
    try {
      await Api.post(`/types/${ticketId}/exports`, {
        reference: values.reference,
        quantity: values.quantity,
      });
      afterCreate();
    } catch (error) {
      console.error(error);
      setError("An error occurred while creating the export");
    }
    setLoading(false);
  };

  const close = () => {
    if (loading) return;
    closeModal();
  };

  return (
    <Modal isVisible={showModal} closeModal={close} icon={RiDownloadLine}>
      <div>
        <Text variant="h3" color="info">
          Create new export
        </Text>
        <Text variant="lead">
          Enter a reference and the number of codes you want to generate.
        </Text>
        <Form
          initialValues={initialValues}
          validationSchema={yupSchema}
          onSubmit={handleSubmit}
        >
          {error && (
            <Message
              alert
              type="error"
              message={error}
              onClick={() => setError(null)}
            />
          )}
          <div style={{ textAlign: "left" }}>
            <FormFieldset disabled={loading}>
              <FormGroup>
                <FormLabel>Reference</FormLabel>
                <FormInput name="reference" />
              </FormGroup>
              <FormGroup>
                <FormLabel>Quantity</FormLabel>
                <FormInput
                  name="quantity"
                  type="number"
                  step={1}
                  min={1}
                  max={10000}
                />
              </FormGroup>
            </FormFieldset>
          </div>
          <ModalButtons>
            <Button type="submit" loading={loading}>
              Create ticket
            </Button>
            <Button variant="link" onClick={close} disabled={loading}>
              Cancel
            </Button>
          </ModalButtons>
        </Form>
      </div>
    </Modal>
  );
};
