import { Modal, ModalButtons, Text, Button, useAuth } from "@eventsquare/uikit";
import { Api } from "@lib/api";
// import styles from "./DeleteUserInvitationModal.module.scss";
import { RiDeleteBinLine } from "@remixicon/react";
import { useState } from "react";

interface DeleteUserInvitationModalProps {
  token: string | undefined;
  setTokenUndefined: () => void;
  afterDelete: () => void;
}

export const DeleteUserInvitationModal = (
  props: DeleteUserInvitationModalProps
) => {
  const { token, setTokenUndefined, afterDelete } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const { accountId } = useAuth();

  const deleteInvitation = async () => {
    setLoading(true);
    try {
      await Api.delete(`/accounts/${accountId}/invitations/${token}`);
      setTokenUndefined();
      afterDelete();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const closeModal = () => {
    if (loading) return;
    setTokenUndefined();
  };

  return (
    <Modal
      isVisible={!!token}
      closeModal={closeModal}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div>
        <div>
          <Text variant="h3" color="error">
            Delete invitation
          </Text>
          <Text variant="p">
            Are you sure you want to delete this invitation? This action cannot
            be undone.
          </Text>
        </div>
        <ModalButtons>
          <Button
            block
            color="error"
            onClick={deleteInvitation}
            loading={loading}
          >
            Delete invitation
          </Button>
          <Button block variant="link" color="subtle" onClick={closeModal}>
            Cancel
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
