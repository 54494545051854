import styles from "./TileGrid.module.scss";

interface TileGridProps {
  columns: number;
  children: React.ReactNode;
}

export const TileGrid = (props: TileGridProps) => {
  const { columns, children } = props;
  return (
    <div
      className={styles.tileGrid}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {children}
    </div>
  );
};
