import styles from "./create.module.scss";
import { useState } from "react";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  Button,
  Container,
  Message,
  PageHeader,
  Section,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import {
  Form,
  FormFieldset,
  FormGroup,
  FormLabel,
  FormInput,
  FormColorPicker,
} from "@eventsquare/uikit/forms";

import { TypeType } from "@type/types";

import { TypesTypeSwitch } from "@components/TypesTypeSwitch/TypesTypeSwitch";

import { getCurrencySymbol } from "@lib/helpers";
import { Api } from "@lib/api";

interface TypeCreateValues {
  type: TypeType;
  name: string;
  price: number | "";
  stock: number;
  color: string;
}

export const EditionTypesCreate = () => {
  const { account, user } = useAuth();
  const { eventId, editionId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initialValues: TypeCreateValues = {
    type: "ticket",
    name: "",
    price: "",
    stock: 2000,
    color: "#068cfc",
  };

  const yupSchema = yup.object().shape({
    name: yup.string().required("Please enter a name"),
    price: yup
      .number()
      .integer()
      .nullable()
      .min(0, "Price must be positive")
      .max(
        10000,
        "Price must be less than 10000, or contact or sales team for custom pricing"
      ),
    stock: yup
      .number()
      .integer()
      .nullable()
      .min(0, "Stock must be positive")
      .max(
        100000,
        "Stock must be less than 100000, or contact our sales team for custom stock"
      ),
  });

  const handleSubmit = async (values: TypeCreateValues) => {
    setLoading(true);
    setError(null);
    try {
      await Api.post(`/editions/${editionId}/types`, {
        type: values.type,
        name: values.name,
        price: values.price ?? null,
        stock: values.stock,
        branding: {
          color: values.color,
        },
      });

      navigate(`/events/${eventId}/editions/${editionId}/tickets?saved=true`);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (!account || !user) return null;

  return (
    <UIPage>
      <PageHeader
        title="Create a new ticket type"
        topAction={
          <Link to={`/events/${eventId}/editions/${editionId}/tickets`}>
            <BackButton>Back to types</BackButton>
          </Link>
        }
      />
      <Container>
        <div className={styles.create}>
          <Section boxed>
            <Text variant="lead">
              Please fill in the details below to create a new ticket.
            </Text>
            <Form
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={handleSubmit}
            >
              {(context) => {
                const { setFieldValue, values } = context;
                const handleTypeChange = (type: TypeType) => {
                  setFieldValue("type", type);
                };

                return (
                  <>
                    {error && (
                      <Message
                        alert
                        type="error"
                        message={error}
                        onClick={() => setError(null)}
                      />
                    )}
                    <FormFieldset disabled={loading}>
                      <FormGroup>
                        <TypesTypeSwitch
                          value={values.type as TypeType}
                          handleTypeChange={handleTypeChange}
                        />
                      </FormGroup>
                      <div className={styles.create__formTop}>
                        <FormGroup>
                          <FormLabel htmlFor="name" helperText="Required">
                            Name
                          </FormLabel>
                          <FormInput name="name" />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="color">Color</FormLabel>
                          <FormColorPicker name="color" />
                        </FormGroup>
                      </div>
                      <div className={styles.create__formBottom}>
                        <FormGroup>
                          <FormLabel htmlFor="price">Price</FormLabel>
                          <FormInput
                            name="price"
                            type="number"
                            prefix={getCurrencySymbol(
                              account.currency,
                              user.language
                            )}
                            step={0.01}
                            min={0}
                            max={10000}
                            placeholder="Free"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="stock">Stock</FormLabel>
                          <FormInput
                            name="stock"
                            type="number"
                            min={0}
                            max={100000}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                    </FormFieldset>
                    <div>
                      <Button type="submit" loading={loading}>
                        Create ticket
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          </Section>
        </div>
      </Container>
    </UIPage>
  );
};
