import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  PageLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  UIPage,
} from "@eventsquare/uikit";
import { RiCheckLine, RiCheckboxMultipleBlankLine } from "@remixicon/react";
import { useCallback, useEffect, useState } from "react";

import { Channel } from "@type/channels";

import { Api } from "@lib/api";
import { stopPropagation } from "@lib/helpers";

// TODO : Does a channel alwasy have a name?

export const EditionChannels = () => {
  const { eventId, editionId } = useParams();
  const navigate = useNavigate();

  const [channels, setChannels] = useState<Channel[]>([]);
  const [channelsTotal, setChannelsTotal] = useState<number | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);

  const fetchChannels = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        limit: 100,
      };
      const { channels, total } = await Api.get(
        `/editions/${editionId}/channels`,
        params
      );
      setChannels(channels);
      setChannelsTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId]);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  return (
    <>
      <UIPage>
        <PageHeader title="Channels" badge={channelsTotal ?? undefined}>
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create channel</Button>
            </Link>
          </ButtonGroup>
        </PageHeader>
        <Container>
          {loading && <PageLoader />}
          {!loading && channels && channels.length === 0 && (
            <Empty
              title={"No channels found"}
              lead={"No channels found"}
              icon={<RiCheckboxMultipleBlankLine />}
            />
          )}
          {!loading && channels && channels.length > 0 && (
            <Table scrollable clickable>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Online</TableHeadCell>
                  <TableHeadCell>POS</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {channels.map((channel) => {
                  const channelUri = `/events/${eventId}/editions/${editionId}/channels/${channel.id}`;
                  return (
                    <TableRow
                      onClick={() => navigate(channelUri)}
                      key={channel.id}
                    >
                      <TableCell>
                        <div>
                          <Link to={channelUri} onClick={stopPropagation}>
                            <strong>{channel.name}</strong>
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell>
                        {channel.store && (
                          <RiCheckLine color={"var(--theme-color-success)"} />
                        )}
                      </TableCell>
                      <TableCell>
                        {channel.pos && (
                          <RiCheckLine color={"var(--theme-color-success)"} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Container>
      </UIPage>
    </>
  );
};
