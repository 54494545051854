import { useCallback, useEffect, useState } from "react";
import { RiTicketLine } from "@remixicon/react";
import {
  Badge,
  Button,
  Loader,
  Modal,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import styles from "./AddTypeModal.module.scss";
import { FormikProps, FormikValues } from "formik";
import {
  Form,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
} from "@eventsquare/uikit/forms";
import * as yup from "yup";

import { Api } from "@lib/api";

import { Type, TypeInShow } from "@type/types";
import { Show } from "@type/shows";
import { InvitationCartItem, InvitationType } from "@type/invitations";
import { Language } from "@type/languages";

import { formatDate, getCurrencySymbol } from "@lib/helpers";

interface AddTypeModalProps {
  showModal: boolean;
  closeModel: () => void;
  editionId: string;
  addToCart: (addType: InvitationCartItem) => void;
  invitationType: InvitationType;
}

const getShowBadge = (show: Show) => {
  if (show.status === "soldout")
    return <Badge color="error" content={"Soldout"} size="small" />;
  if (show.status === "low")
    return (
      <Badge
        color="warning"
        content={`${show.attendees.available} attendees available`}
        size="small"
      />
    );
  return null;
};

const getTypeBadge = (type: TypeInShow) => {
  if (type.status === "soldout")
    return <Badge color="error" content={"Soldout"} size="small" />;
  if (type.status === "low")
    return (
      <Badge
        color="warning"
        content={`${type.stock.available} available`}
        size="small"
      />
    );

  return null;
};

export const AddTypeModal = (props: AddTypeModalProps) => {
  const { showModal, closeModel, editionId, addToCart, invitationType } = props;

  const { account, user } = useAuth();

  const [activeTab, setActiveTab] = useState<"tickets" | "vouchers" | "shows">(
    "tickets"
  );
  const [fetchingTypes, setFetchingTypes] = useState<boolean>(true);
  const [fetchingShows, setFetchingShows] = useState<boolean>(true);
  const [shows, setShows] = useState<Show[] | undefined>(undefined);
  const [typesTicket, setTypesTicket] = useState<Type[] | undefined>(undefined);
  const [typesVoucher, setTypesVoucher] = useState<Type[] | undefined>(
    undefined
  );

  const fetchTypes = useCallback(async () => {
    setFetchingTypes(true);
    try {
      const { types } = await Api.get(`/editions/${editionId}/types`, {
        filter: { used_in_shows: 0 },
      });
      const tickets = types.filter((type: Type) => type.type === "ticket");
      const vouchers = types.filter((type: Type) => type.type === "voucher");
      setTypesTicket(tickets);
      setTypesVoucher(vouchers);
      setFetchingTypes(false);
    } catch (error) {
      console.log(error);
    }
  }, [editionId]);

  const fetchShows = useCallback(async () => {
    setFetchingShows(true);
    try {
      const { shows } = await Api.get(`/editions/${editionId}/shows`);
      setShows(shows);
      if (shows.length) {
        setActiveTab("shows");
      } else {
        setActiveTab("tickets");
      }
      setFetchingShows(false);
    } catch (error) {
      console.log(error);
    }
  }, [editionId]);

  useEffect(() => {
    if (showModal) {
      fetchTypes();
      fetchShows();
    } else {
      setShows(undefined);
      setTypesTicket(undefined);
      setTypesVoucher(undefined);
    }
  }, [showModal, fetchTypes, fetchShows]);

  const close = () => {
    closeModel();
    setShows(undefined);
    setTypesTicket(undefined);
    setTypesVoucher(undefined);
  };

  const submitHandlerTicket = (values: {
    ticket: string;
    quantity: number;
    price: string;
  }) => {
    const type = typesTicket?.find((type) => type.id === values.ticket);
    if (!type) return;
    submit(type, null, +values.quantity, values.price);
  };

  const submitHandlerVoucher = (values: {
    voucher: string;
    quantity: number;
    price: string;
  }) => {
    const type = typesVoucher?.find((type) => type.id === values.voucher);
    if (!type) return;
    submit(type, null, +values.quantity, values.price);
  };

  const submitHandlerShow = (values: {
    show: string;
    showTypeId: string;
    showType: TypeInShow | null;
    quantity: number;
    price: string;
  }) => {
    if (!values.showType) return;
    submit(values.showType, values.show, +values.quantity, values.price);
  };

  const submit = (
    type: Type | TypeInShow,
    show: string | null,
    quantity: number,
    customPrice: string
  ) => {
    const submitPrice =
      invitationType === "free" || customPrice === ""
        ? type.price
        : parseFloat(customPrice);
    const submitShow = show ? shows?.find((s) => s.id === show) : null;

    const addItem = {
      id: type.id,
      name: type.name,
      price: submitPrice,
      quantity: quantity,
      show: submitShow ?? null,
    };

    addToCart(addItem);
    close();
  };

  const shouldWeShowTheTabs = () => {
    // return true is we have at least 2 tabs
    let count = 0;
    if (typesTicket && typesTicket.length > 0) count++;
    if (typesVoucher && typesVoucher.length > 0) count++;
    if (shows && shows.length > 0) count++;
    return count > 1;
  };

  return (
    <Modal isVisible={showModal} closeModal={close} icon={RiTicketLine}>
      <div className={styles.modal}>
        <Text variant="h3" color="info">
          Voeg een ticket toe
        </Text>
        {(fetchingTypes || fetchingShows) && (
          <div className={styles.modalLoader}>
            <Loader />
          </div>
        )}
        {!fetchingTypes && !fetchingShows && (
          <>
            {shouldWeShowTheTabs() && (
              <div className={styles.modalTabs}>
                <div className={styles.modalTabs__inner}>
                  {shows && shows.length > 0 && (
                    <button
                      className={`${styles.modalTabs__tab} ${
                        activeTab === "shows" ? styles.modalTabs__tabActive : ""
                      }`}
                      onClick={() => setActiveTab("shows")}
                    >
                      Shows
                    </button>
                  )}
                  {typesTicket && typesTicket.length > 0 && (
                    <button
                      className={`${styles.modalTabs__tab} ${
                        activeTab === "tickets"
                          ? styles.modalTabs__tabActive
                          : ""
                      }`}
                      onClick={() => setActiveTab("tickets")}
                    >
                      Tickets
                    </button>
                  )}
                  {typesVoucher && typesVoucher.length > 0 && (
                    <button
                      className={`${styles.modalTabs__tab} ${
                        activeTab === "vouchers"
                          ? styles.modalTabs__tabActive
                          : ""
                      }`}
                      onClick={() => setActiveTab("vouchers")}
                    >
                      Vouchers
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className={styles.modalForm}>
              {activeTab === "tickets" && (
                <Form
                  initialValues={{ ticket: "", quantity: 1, price: "" }}
                  onSubmit={submitHandlerTicket}
                  validationSchema={yup.object().shape({
                    ticket: yup.string().required("Ticket is required"),
                    quantity: yup.number().min(1, "Quantity is required"),
                  })}
                >
                  {(context) => {
                    const { values } = context;
                    return (
                      <>
                        <FormGroup>
                          <FormLabel htmlFor="ticket">Ticket</FormLabel>
                          <FormSelect
                            name="ticket"
                            placeholder="Select a ticket"
                            options={
                              typesTicket?.map((type) => {
                                return {
                                  value: type.id,
                                  label: type.name,
                                };
                              }) ?? []
                            }
                            disabled={!typesTicket?.length}
                            search
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="quantity">Quantity</FormLabel>
                          <FormInput
                            name="quantity"
                            type="number"
                            min={1}
                            step={1}
                          />
                        </FormGroup>

                        {invitationType === "paylink" && (
                          <FormGroup>
                            <FormLabel htmlFor="quantity">Price</FormLabel>
                            <FormInput
                              name="price"
                              type="number"
                              placeholder="Original price"
                              step={0.01}
                              min={0}
                              prefix={getCurrencySymbol(account!.currency)}
                            />
                          </FormGroup>
                        )}
                        <div>
                          <Button block type="submit" disabled={!values.ticket}>
                            Add to cart
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form>
              )}
              {activeTab === "vouchers" && (
                <Form
                  initialValues={{ voucher: "", quantity: 1, price: "" }}
                  onSubmit={submitHandlerVoucher}
                  validationSchema={yup.object().shape({
                    voucher: yup.string().required("Voucher is required"),
                    quantity: yup.number().min(1, "Quantity is required"),
                  })}
                >
                  {(context) => {
                    const { values } = context;
                    return (
                      <>
                        <FormGroup>
                          <FormLabel htmlFor="voucher">Voucher</FormLabel>
                          <FormSelect
                            name="voucher"
                            placeholder="Select a voucher"
                            options={
                              typesVoucher?.map((type) => {
                                return {
                                  value: type.id,
                                  label: type.name,
                                };
                              }) ?? []
                            }
                            disabled={!typesVoucher?.length}
                            search
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="quantity">Quantity</FormLabel>
                          <FormInput
                            name="quantity"
                            type="number"
                            min={1}
                            step={1}
                          />
                        </FormGroup>

                        {invitationType === "paylink" && (
                          <FormGroup>
                            <FormLabel htmlFor="quantity">Price</FormLabel>
                            <FormInput
                              name="price"
                              type="number"
                              placeholder="Original price"
                              step={0.01}
                              min={0}
                              prefix={getCurrencySymbol(account!.currency)}
                            />
                          </FormGroup>
                        )}
                        <div>
                          <Button
                            block
                            type="submit"
                            disabled={!values.voucher}
                          >
                            Add to cart
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form>
              )}

              {activeTab === "shows" && (
                <Form
                  initialValues={{
                    show: "",
                    showTypeId: "",
                    showType: null,
                    quantity: 1,
                    price: "",
                  }}
                  onSubmit={submitHandlerShow}
                  validationSchema={yup.object().shape({
                    show: yup.string().required("Show is required"),
                    showTypeId: yup.string().required("Show type is required"),
                    quantity: yup.number().min(1, "Quantity is required"),
                  })}
                >
                  {(context) => (
                    <ShowForm
                      context={context}
                      shows={shows}
                      language={user!.language}
                      invitationType={invitationType}
                      currency={account!.currency}
                    />
                  )}
                </Form>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

interface ShowFormProps {
  context: FormikProps<FormikValues>;
  shows: Show[] | undefined;
  invitationType: InvitationType;
  language: Language;
  currency: string;
}

const ShowForm = (props: ShowFormProps) => {
  const { context, shows, invitationType, language, currency } = props;

  const { values, setFieldValue, setFieldTouched, setFieldError } = context;

  const [typesInShow, setTypesInShow] = useState<TypeInShow[] | undefined>(
    undefined
  );

  useEffect(() => {
    setTypesInShow(undefined);
    setFieldTouched("showTypeId", undefined, true);
  }, [setFieldTouched, shows]);

  useEffect(() => {
    const handleShowchange = async (showId: string) => {
      if (showId === "") {
        return;
      }
      try {
        const { types } = await Api.get(`/shows/${showId}/types`);
        setTypesInShow(types);

        await setFieldValue("showTypeId", "", false);
        await setFieldTouched("showTypeId", undefined, true);
        await setFieldError("showTypeId", undefined);
      } catch (error) {
        console.log(error);
      }
    };

    setTypesInShow(undefined);
    handleShowchange(values.show);
  }, [values.show, setFieldError, setFieldTouched, setFieldValue]);

  useEffect(() => {
    const handleShowTypeChange = async () => {
      if (!values.showTypeId || !typesInShow || typesInShow.length === 0) {
        await setFieldValue("showType", null, false);
        return;
      }

      await setFieldError("showTypeId", undefined);
      await setFieldValue(
        "showType",
        typesInShow?.find((type) => type.id === values.showTypeId) ?? null,
        false
      );
    };

    handleShowTypeChange();
  }, [values.showTypeId, typesInShow, setFieldValue, setFieldError]);

  const getShowName = (show: Show) => {
    if (show.name && show.date.start)
      return show.name + " - " + formatDate(show.date.start, language);
    if (show.name) return show.name;
    if (show.date.start) {
      const date = formatDate(show.date.start, language);
      return date[0].toUpperCase() + date.slice(1);
    }
    return show.id;
  };

  if (!shows || !shows.length) return null;

  return (
    <>
      <FormGroup>
        <FormLabel htmlFor="show">Show</FormLabel>
        <FormSelect
          name="show"
          placeholder="Select a show"
          options={shows!.map((show) => {
            return {
              value: show.id,
              label: getShowName(show),
              sublabel: getShowBadge(show),
              disabled: show.status === "soldout",
            };
          })}
          search
        />
      </FormGroup>

      {typesInShow && typesInShow.length === 0 && (
        <Text variant="small">No types available for this show</Text>
      )}

      <FormGroup>
        <FormLabel htmlFor="showTypeId">Show type</FormLabel>
        <FormSelect
          name="showTypeId"
          placeholder="Select a show type"
          options={
            typesInShow
              ? typesInShow!.map((type) => {
                  return {
                    value: type.id,
                    label: type.name,
                    sublabel: getTypeBadge(type),
                    disabled: type.status === "soldout",
                  };
                })
              : []
          }
          disabled={!values.show || !typesInShow?.length}
          search
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="quantity">Quantity</FormLabel>
        <FormInput name="quantity" type="number" min={1} step={1} />
      </FormGroup>
      {invitationType === "paylink" && (
        <FormGroup>
          <FormLabel htmlFor="quantity">Price</FormLabel>
          <FormInput
            name="price"
            type="number"
            placeholder="Original price"
            step={0.01}
            min={0}
            prefix={getCurrencySymbol(currency)}
          />
        </FormGroup>
      )}
      <div>
        <Button block type="submit" disabled={!values.showType}>
          Add to cart
        </Button>
      </div>
    </>
  );
};
