import { createContext, useContext } from "react";

import { Event } from "@type/events";
import { Edition } from "@type/editions";

export interface EditionContextInterface {
  event: Event;
  edition: Edition;
}

export const EditionContext = createContext<
  EditionContextInterface | undefined
>(undefined);

export const useEditionContext = () => {
  return useContext(EditionContext) as EditionContextInterface;
};
