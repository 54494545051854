export const getOptions = (colorMode: "light" | "dark") => {
  return {
    responsive: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        ticks: {
          color: colorMode === "light" ? "#5e84a4" : "#5e84a4", //theme-form-color-subtle
          source: "data",
          padding: 8,
          font: {
            family:
              '"Proxima Vara",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            size: 10,
            weight: 500,
          },
          maxRotation: 0,
        },
        grid: {
          display: false,
          offset: false,
        },
        time: {
          isoWeekday: true,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          color: colorMode === "light" ? "#5e84a4" : "#5e84a4", //theme-form-color-subtle
          padding: 8,
          font: {
            family:
              '"Proxima Vara",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            size: 10,
            weight: 500,
          },
        },
        grid: {
          color: colorMode === "light" ? "#e8eef2" : "#253441", //theme-lines
          offset: false,
        },
        stacked: true,
        min: 0,
      },
    },
  };
};
