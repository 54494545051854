import { useCallback, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  Message,
  PageHeader,
  PageLoader,
  UIPage,
} from "@eventsquare/uikit";
import { RiTicketLine } from "@remixicon/react";

import { Api } from "@lib/api";

import { TypesTable } from "@components/TypesTable/TypesTable";

import { Type, TypeType } from "@type/types";

export const EditionTypes = () => {
  const { eventId, editionId } = useParams();
  const [searchParams] = useSearchParams();

  const [typesTickets, setTypesTickets] = useState<Type[]>([]);
  const [typesVouchers, setTypesVouchers] = useState<Type[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [typeRemoved, setTypeRemoved] = useState<boolean>(false);
  const [typeCreated, setTypeCreated] = useState<boolean>(false);

  useEffect(() => {
    const removed = searchParams.get("removed");
    if (removed && removed === "true") {
      setTypeRemoved(true);
    }

    const saved = searchParams.get("saved");
    if (saved && saved === "true") {
      setTypeCreated(true);
    }
  }, []);

  const fetchTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { types } = await Api.get(`/editions/${editionId}/types`);
      const tickets = types.filter((type: Type) => type.type === "ticket");
      const vouchers = types.filter((type: Type) => type.type === "voucher");
      setTypesTickets(tickets);
      setTypesVouchers(vouchers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId]);

  const updateSorting = async (sortedArr: Type[], type: TypeType) => {
    const newArr =
      type === "ticket"
        ? [...sortedArr, ...typesVouchers]
        : [...typesTickets, ...sortedArr];
    try {
      await Api.post(`/editions/${editionId}/types/sort`, {
        types: newArr.map((type) => type.id),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, [fetchTypes]);

  return (
    <>
      <UIPage>
        <PageHeader title="Tickets">
          <ButtonGroup noMargin>
            <Link
              to={`/events/${eventId}/editions/${editionId}/tickets/create`}
            >
              <Button variant="outline">Create new ticket</Button>
            </Link>
          </ButtonGroup>
        </PageHeader>
        <Container>
          {loading && <PageLoader />}
          {!loading && typeRemoved && (
            <Message
              alert
              type="info"
              message={"Type has been removed successfully"}
              onClick={() => setTypeRemoved(false)}
            />
          )}
          {!loading && typeCreated && (
            <Message
              alert
              type="success"
              message={"Type has been created successfully"}
              onClick={() => setTypeCreated(false)}
            />
          )}
          {!loading && !typesTickets.length && !typesVouchers.length && (
            <Empty
              title="No tickets yet"
              lead="Let's create your first ticket type for your event!"
              icon={<RiTicketLine />}
            >
              <ButtonGroup noMargin>
                <Link
                  to={`/events/${eventId}/editions/${editionId}/tickets/create`}
                >
                  <Button>Create new ticket</Button>
                </Link>
              </ButtonGroup>
            </Empty>
          )}
          {!loading && (!!typesTickets.length || !!typesVouchers.length) && (
            <>
              <TypesTable
                typeType="ticket"
                types={typesTickets}
                setTypes={setTypesTickets}
                eventId={eventId!}
                editionId={editionId!}
                updateSorting={updateSorting}
              />
              <TypesTable
                typeType="voucher"
                types={typesVouchers}
                setTypes={setTypesVouchers}
                eventId={eventId!}
                editionId={editionId!}
                updateSorting={updateSorting}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
