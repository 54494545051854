import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  PageHeader,
  PageLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Toggle,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import {
  FilterBar,
  FilterCheckbox,
  FilterToggle,
} from "@eventsquare/uikit/filters";
import { useCallback, useEffect, useState } from "react";
import { RiVipCrownLine } from "@remixicon/react";

import { Deal } from "@type/deals";

import { Api } from "@lib/api";
import { formatDate, stopPropagation } from "@lib/helpers";

export const EditionDeals = () => {
  const { eventId, editionId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hideInactive, setHideInactive] = useState<boolean>(false);

  const fetchDeals = useCallback(async () => {
    try {
      setLoading(true);
      const { deals } = await Api.get(`/editions/${editionId}/deals`);
      setDeals(deals);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const updateDeal = async (deal: Deal) => {
    const dealsArr = [...deals];
    const updatedDeal = { ...deal, active: !deal.active };
    setDeals(deals.map((d) => (d.id === deal.id ? updatedDeal : d)));
    try {
      await Api.put(`/deals/${deal.id}`, {
        active: !deal.active ? 1 : 0,
      });
      // replace the updated deal in the list
    } catch (error) {
      console.error(error);
      // revert the change
      setDeals(dealsArr);
    }
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Deals">
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create new deal</Button>
            </Link>
            <FilterToggle filterActive={hideInactive} />
          </ButtonGroup>
        </PageHeader>
        <Container>
          <FilterBar>
            <FilterCheckbox
              label="Hide inactive"
              label_checked="Inactive hidden"
              checked={hideInactive}
              onChange={() => setHideInactive(!hideInactive)}
            />
          </FilterBar>
          {loading && <PageLoader />}
          {!loading && deals && deals.length === 0 && (
            <Empty
              title={"No deals found"}
              lead={"No deals found"}
              icon={<RiVipCrownLine />}
            />
          )}
          {!loading && deals && deals.length > 0 && (
            <Table scrollable clickable>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Created at</TableHeadCell>
                  {/* <TableHeadCell textAlign="right">Used</TableHeadCell> */}
                  <TableHeadCell width="4rem" textAlign="right">
                    Active
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deals
                  .filter((deal) => (hideInactive ? deal.active : true))
                  .map((deal) => {
                    const dealUrl = `/events/${eventId}/editions/${editionId}/deals/${deal.id}`;
                    return (
                      <TableRow key={deal.id} onClick={() => navigate(dealUrl)}>
                        <TableCell>
                          <div>
                            <Link to={dealUrl} onClick={stopPropagation}>
                              <strong>{deal.name}</strong>
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell>
                          {formatDate(deal.created_at, user!.language, false)}
                        </TableCell>
                        {/* <TableCell textAlign="right">{deal.used}</TableCell> */}
                        <TableCell textAlign="right">
                          <div onClick={stopPropagation}>
                            <Toggle
                              checked={deal.active}
                              onChange={() => {
                                updateDeal(deal);
                              }}
                              size="small"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </Container>
      </UIPage>
    </>
  );
};
