import { Button, Count, ProgressBar } from "@eventsquare/uikit";
import styles from "./Dashboard.module.scss";
import { RiGroupLine } from "@remixicon/react";
import { Link } from "react-router-dom";

interface DashboardProps {
  barColor?: "info" | "success" | "warning" | "error" | "disabled" | "live";
  children: React.ReactNode;
  guestlistLink?: string;
  issued: number;
  pending: number;
  title?: string;
  total: number;
}

export const Dashboard = (props: DashboardProps) => {
  const {
    barColor = "info",
    children,
    guestlistLink,
    issued,
    pending,
    title,
    total,
  } = props;

  // TODO : translate Issued , Guestlist

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardHero}>
        <div className={styles.dashboardHero__top}>
          <h3 className={styles.dashboardHero__title}>{title || "Issued"}</h3>
          {issued > 0 && guestlistLink && (
            <p className={styles.dashboardHero__guestlistLink}>
              <Link to={guestlistLink}>
                <Button variant="outline" size="tiny">
                  <RiGroupLine /> Guestlist
                </Button>
              </Link>
            </p>
          )}
        </div>
        <h2 className={styles.dashboardHero__issued}>
          <Count end={issued} />
        </h2>
        <div>
          <ProgressBar
            size="medium"
            value={issued}
            total={total}
            pending={pending}
            color={barColor}
            showLabel={false}
          />
        </div>
      </div>

      <div className={styles.dashboardGrid}>{children}</div>
    </div>
  );
};
