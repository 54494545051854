import styles from "./questions.module.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  Message,
  PageHeader,
  PageLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { RiCheckLine, RiDeleteBin2Line, RiEdit2Line } from "@remixicon/react";
import { useCallback, useEffect, useState } from "react";

import { Api } from "@lib/api";
import { stopPropagation } from "@lib/helpers";

import { Question } from "@type/questions";

import { QuestionIcon } from "@components/QuestionIcon/QuestionIcon";
import { DeleteQuestionModal } from "@components/modals/DeleteQuestionModal/DeleteQuestionModal";

const Colgroup = () => {
  return (
    <colgroup>
      <col />
      <col width="2rem" />
      <col width="8rem" />
    </colgroup>
  );
};

export const SettingsQuestions = () => {
  const navigate = useNavigate();
  const { accountId } = useAuth();

  const [questions, setQuestions] = useState<Question[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [questionToDelete, setQuestionToDelete] = useState<
    Question | undefined
  >(undefined);
  const [alert, setAlert] = useState<
    | { message: string; type: "info" | "success" | "error" | "warning" }
    | undefined
  >(undefined);

  const fetchQuestions = useCallback(async () => {
    // Fetch questions from API
    try {
      const { questions } = await Api.get(`/accounts/${accountId}/questions`);
      setQuestions(questions);
    } catch (error) {
      console.log(error);
      setQuestions(null);
    } finally {
      setLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const handleDelete = () => {
    if (!questionToDelete || !questions) return;
    const newQuestions = questions.filter(
      (question) => question.id !== questionToDelete.id
    );
    setQuestions(newQuestions);
    setQuestionToDelete(undefined);
    setAlert({
      message: "Question deleted",
      type: "info",
    });
  };

  return (
    <UIPage>
      {loading && <PageLoader />}

      {!loading && (
        <>
          <PageHeader title="Settings Questions">
            <ButtonGroup noMargin>
              <Link to="create">
                <Button variant="outline">Create new question</Button>
              </Link>
            </ButtonGroup>
          </PageHeader>
          <Container>
            {alert && (
              <Message
                alert
                message={alert.message}
                type={alert.type}
                onClick={() => setAlert(undefined)}
              />
            )}
            {(!questions || questions.length === 0) && (
              <Empty
                title="No questions yet"
                lead="Create your first questions by clicking on the button above"
              />
            )}
            {questions && questions.length >= 1 && (
              <Table scrollable clickable>
                <Colgroup />
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Question</TableHeadCell>
                    <TableHeadCell width="2rem">Active</TableHeadCell>
                    <TableHeadCell width="8rem" hideMobile></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((question) => {
                    return (
                      <TableRow
                        key={question.id}
                        onClick={() => navigate(`${question.id}`)}
                      >
                        <TableCell>
                          <div className={styles.question__name}>
                            <div className={styles.question__icon}>
                              <QuestionIcon type={question.type} />
                            </div>
                            <div className={styles.question__text}>
                              <div>
                                <Link
                                  to={`${question.id}`}
                                  onClick={stopPropagation}
                                >
                                  <strong>{question.name}</strong>
                                </Link>
                              </div>
                              <Text variant="small" noMargin>
                                {question.type}
                              </Text>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {question.has_questionables ? (
                            <RiCheckLine color={"var(--theme-color-success)"} />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell hideMobile>
                          <ButtonGroup noMargin noWrap>
                            <Link
                              to={`${question.id}`}
                              onClick={stopPropagation}
                            >
                              <Button variant="outline" icon>
                                <RiEdit2Line />
                              </Button>
                            </Link>
                            <Button
                              variant="outline"
                              color="error"
                              icon
                              onClick={(
                                e?: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                if (e) stopPropagation(e);
                                setQuestionToDelete(question);
                              }}
                            >
                              <RiDeleteBin2Line />
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Container>
        </>
      )}
      <DeleteQuestionModal
        question={questionToDelete}
        afterDelete={() => handleDelete()}
        cancelDelete={() => setQuestionToDelete(undefined)}
      />
    </UIPage>
  );
};
