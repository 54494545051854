import { Button, Modal, ModalButtons, Text } from "@eventsquare/uikit";
import { Api } from "@lib/api";
import { RiDeleteBinLine } from "@remixicon/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface DeleteInvitationModalProps {
  invitationId: string | undefined;
  setInvitationId: (str: string | undefined) => void;
}

export const DeleteInvitationModal = (props: DeleteInvitationModalProps) => {
  const { invitationId, setInvitationId } = props;
  const navigate = useNavigate();
  const { eventId, editionId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);

  const close = () => {
    setInvitationId(undefined);
  };

  const deleteInvitation = async () => {
    if (!invitationId) return;
    setLoading(true);
    try {
      await Api.delete(`/invitations/${invitationId}`);
      close();
      navigate(
        `/events/${eventId}/editions/${editionId}/invitations?removed=true`
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isVisible={!!invitationId}
      closeModal={close}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div>
        <Text variant="h3" color="error">
          Delete invitation
        </Text>
        <Text variant="lead">
          Are you sure you want to delete this invitation? This action cannot be
          undone.
        </Text>
      </div>
      <ModalButtons>
        <Button
          block
          color="error"
          onClick={deleteInvitation}
          loading={loading}
        >
          Delete invitation
        </Button>
        <Button block variant="link" onClick={close} disabled={loading}>
          Cancel
        </Button>
      </ModalButtons>
    </Modal>
  );
};
