import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  UIPage,
} from "@eventsquare/uikit";

export const EditionChannelsLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[7];

  return (
    <>
      <UIPage>
        <PageHeader title="Channels" />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem label="Online" active={path === undefined} />
            </Link>
            <Link to="layout">
              <MobileTopNavbarItem label="Layout" active={path === "layout"} />
            </Link>
            <Link to="tickets">
              <MobileTopNavbarItem
                label="Tickets"
                active={path === "tickets"}
              />
            </Link>
            <Link to="shows">
              <MobileTopNavbarItem label="Shows" active={path === "shows"} />
            </Link>
            <Link to="pos">
              <MobileTopNavbarItem label="POS" active={path === "pos"} />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </>
  );
};
