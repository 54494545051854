import {
  RiCalendarLine,
  RiCheckboxLine,
  RiDropdownList,
  RiInputMethodLine,
} from "@remixicon/react";

export const QuestionIcon = ({
  type,
}: {
  type: "date" | "text" | "select" | "checkbox";
}) => {
  switch (type) {
    case "date":
      return <RiCalendarLine />;
    case "text":
      return <RiInputMethodLine />;
    case "select":
      return <RiDropdownList />;
    case "checkbox":
      return <RiCheckboxLine />;
    default:
      return null;
  }
};
