import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  UIAppLayout,
  UIAppSidebar,
  UIAppContent,
  SidebarButton,
  SidebarButtonLogo,
  SidebarBottom,
  useTheme,
  SidebarUserMenu,
} from "@eventsquare/uikit";

import {
  RiBarChart2Line,
  RiFunctionLine,
  RiGroupLine,
  RiMoonLine,
  RiSettings3Line,
  RiSunLine,
} from "@remixicon/react";
import { APIProvider } from "@vis.gl/react-google-maps";

export const AppLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const { colorMode, setColorMode } = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleTheme = () => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  };

  // Toggle color mode on keypress $
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.code === "BracketRight") {
        handleToggleTheme();
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [handleToggleTheme]);

  return (
    <UIAppLayout>
      <UIAppSidebar>
        <Link to="events">
          <SidebarButtonLogo />
        </Link>
        <Link to="events">
          <SidebarButton
            icon={RiFunctionLine}
            label="Events"
            active={path === "events"}
          />
        </Link>
        <Link to="customers">
          <SidebarButton
            icon={RiGroupLine}
            label="Customers"
            active={path === "customers"}
          />
        </Link>
        <Link to="statistics">
          <SidebarButton
            icon={RiBarChart2Line}
            label="Statistics"
            active={path === "statistics"}
          />
        </Link>
        <Link to="settings">
          <SidebarButton
            icon={RiSettings3Line}
            label="Settings"
            active={path === "settings"}
          />
        </Link>

        <SidebarBottom>
          <SidebarUserMenu
            translations={{
              switch_account: "Switch account",
              logout: "Logout",
              change_avatar: "Change avatar",
              switch_language: "Switch language",
              languages: {
                nl: "Dutch",
                en: "English",
                fr: "French",
              },
            }}
          />
          <SidebarButton
            icon={colorMode === "light" ? RiSunLine : RiMoonLine}
            label="Toggle dark-mode"
            onClick={handleToggleTheme}
          />
        </SidebarBottom>
      </UIAppSidebar>
      <UIAppContent>
        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API}>
          <Outlet />
        </APIProvider>
      </UIAppContent>
    </UIAppLayout>
  );
};
