import styles from "./create.module.scss";
import { useState } from "react";
import {
  Container,
  Message,
  Section,
  Text,
  Button,
  UIPage,
  PageHeader,
  BackButton,
} from "@eventsquare/uikit";
import {
  Form,
  FormCheckbox,
  FormDatePicker,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
} from "@eventsquare/uikit/forms";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Api } from "@lib/api";
import { dateToSql } from "@lib/helpers";

import * as yup from "yup";

interface ShowCreateValues {
  date_start: Date | null;
  date_end: Date | null;
  name: string;
  attendees_limit: number | "";
  active: boolean;
}

export const EditionShowsCreate = () => {
  const { eventId, editionId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | string[] | undefined>(undefined);

  const initialValues: ShowCreateValues = {
    name: "",
    date_start: null,
    date_end: null,
    attendees_limit: "",
    active: true,
  };

  const yupSchema = yup.object().shape({
    date_start: yup.date().nullable(),
    date_end: yup.date().nullable(),
    name: yup.string().nullable(),
    attendees_limit: yup.lazy((value) => {
      if (value === "") {
        return yup.string().nullable();
      } else {
        return yup.number().integer().positive().nullable();
      }
    }),
  });

  const handleSubmit = async (values: ShowCreateValues) => {
    if (loading) return;
    setLoading(true);
    setError(undefined);
    try {
      await Api.post(`/editions/${editionId}/shows`, {
        name: values.name,
        date: {
          start: values.date_start ? dateToSql(values.date_start) : "",
          end: values.date_end ? dateToSql(values.date_end) : "",
        },
        attendees: {
          limit: values.attendees_limit ? values.attendees_limit : "",
        },
        active: values.active,
      });

      navigate(`/events/${eventId}/editions/${editionId}/shows?saved=true`);
    } catch (error) {
      console.log(error);
      // TODO: handle error
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <UIPage>
      <PageHeader
        title="Create a new show"
        topAction={
          <Link to={`/events/${eventId}/editions/${editionId}/shows`}>
            <BackButton>Go back to shows</BackButton>
          </Link>
        }
      />

      <Container>
        <div className={styles.createShow}>
          <Section boxed>
            <Text variant="lead">
              Please fill in the details below to create a new ticket.
            </Text>
            <Form
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={handleSubmit}
              maxWidth={800}
            >
              <>
                {error && (
                  <Message
                    type="error"
                    message={error}
                    onClick={() => setError(undefined)}
                  />
                )}
                <FormFieldset>
                  <FormGroup>
                    <FormLabel htmlFor="date_start">Date start</FormLabel>
                    <FormDatePicker
                      name="date_start"
                      hideHours={false}
                      clearButton
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="date_end" helperText="Optional">
                      Date end
                    </FormLabel>
                    <FormDatePicker
                      name="date_end"
                      hideHours={false}
                      clearButton
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="name" helperText="Optional">
                      Name
                    </FormLabel>
                    <FormInput name="name" />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="attendees_limit">Attendees</FormLabel>
                    <FormInput
                      name="attendees_limit"
                      type="number"
                      placeholder="Unlimited"
                      suffix="attendees"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormCheckbox name="active" label="Activate show" />
                  </FormGroup>
                </FormFieldset>
                <div>
                  <Button type="submit" loading={loading}>
                    Create show
                  </Button>
                </div>
              </>
            </Form>
          </Section>
        </div>
      </Container>
    </UIPage>
  );
};
