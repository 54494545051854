import {
  Button,
  Message,
  Modal,
  ModalButtons,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import {
  Form,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
} from "@eventsquare/uikit/forms";
import { RiTicketLine } from "@remixicon/react";
import { useState } from "react";
import * as yup from "yup";

import { ShowListType } from "@type/shows";
import { getCurrencySymbol } from "@lib/helpers";
import { Api } from "@lib/api";

interface EditTypeInShowModalProps {
  showId: string;
  type: ShowListType | undefined;
  setType: (type: ShowListType | undefined) => void;
  afterUpdate: () => Promise<void>;
}

interface EditTypeFormValues {
  attendees_limit: string | number | "";
  price_override: string | number | "";
}

export const EditTypeInShowModal = (props: EditTypeInShowModalProps) => {
  const { showId, type, setType, afterUpdate } = props;

  const { account, user } = useAuth();

  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<string | string[] | undefined>(undefined);

  const initialFormValues: EditTypeFormValues = {
    attendees_limit: type?.limit ?? "",
    price_override: type?.price_override ?? "",
  };

  const close = () => {
    setType(undefined);
  };

  const yupSchema = yup.object().shape({
    attendees_limit: yup.lazy((value) => {
      if (value === "") {
        return yup.string().nullable();
      } else {
        return yup.number().integer().positive().nullable();
      }
    }),
    price_override: yup.lazy((value) => {
      if (value === "") {
        return yup.string().nullable();
      } else {
        return yup.number().positive().nullable();
      }
    }),
  });

  const submitHandler = async (values: EditTypeFormValues) => {
    setSending(true);

    try {
      const response = await Api.post(`/shows/${showId}/types`, {
        type: type?.id,
        limit: values.attendees_limit === "" ? null : values.attendees_limit,
        price: values.price_override === "" ? null : values.price_override,
      });
      if (response.success) {
        await afterUpdate();
        close();
      }
    } catch (error) {
      // TODO: handle error
      console.error(error);
      setError("Someting went wrong, please try again later");
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal isVisible={!!type} closeModal={close} icon={RiTicketLine}>
      <div>
        <Text variant="h3">{type?.name}</Text>
      </div>
      <Form
        initialValues={initialFormValues}
        onSubmit={submitHandler}
        validationSchema={yupSchema}
      >
        {error && (
          <Message
            alert
            type="error"
            message={error}
            onClick={() => setError(undefined)}
          />
        )}
        <FormFieldset disabled={sending}>
          <FormGroup>
            <FormLabel htmlFor="attendees_limit">Attendees limit</FormLabel>
            <FormInput
              type="number"
              name="attendees_limit"
              suffix="tickets"
              placeholder="Unlimited"
              step={1}
              max={100000}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="price_override">Price override</FormLabel>
            <FormInput
              type="number"
              step={0.01}
              min={0}
              max={100000}
              name="price_override"
              prefix={getCurrencySymbol(account!.currency, user!.language)}
              placeholder={"Original price"}
            />
          </FormGroup>
        </FormFieldset>
        <ModalButtons>
          <Button type="submit" loading={sending} disabled={sending}>
            Edit type
          </Button>
          <Button
            variant="link"
            color="subtle"
            onClick={close}
            disabled={sending}
          >
            Cancel
          </Button>
        </ModalButtons>
      </Form>
    </Modal>
  );
};
