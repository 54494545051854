import { useCallback, useEffect, useState, useRef } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import {
  BackButton,
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  PageLoader,
  UIPage,
} from "@eventsquare/uikit";

import { Api } from "@lib/api";

import { TypeContext, TypeContextInterface } from "@context/typeContext";

import { TypeDetails } from "@type/types";

export const EditionTypesLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[7];
  const { eventId, editionId, ticketId } = useParams();

  const pageRef = useRef<HTMLDivElement>(null);

  const [type, setType] = useState<TypeDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchType = useCallback(async () => {
    if (!ticketId) return;
    try {
      setLoading(true);
      const { type } = await Api.get(`/types/${ticketId}`);
      setType(type);
    } catch (error) {
      // TODO : handle error
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchType();
  }, [fetchType]);

  const scrollUp = () => {
    if (!pageRef.current) return;
    pageRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!type) return null;

  return (
    <TypeContext.Provider
      value={
        {
          type,
          setType,
          fetchType,
          scrollUp,
        } satisfies TypeContextInterface
      }
    >
      <UIPage ref={pageRef}>
        <PageHeader
          title={type!.name}
          topAction={
            <Link to={`/events/${eventId}/editions/${editionId}/tickets`}>
              <BackButton>Back to types</BackButton>
            </Link>
          }
        />

        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>
            <Link to="settings">
              <MobileTopNavbarItem
                label="Settings"
                active={path === "settings"}
              />
            </Link>
            <Link to="layout">
              <MobileTopNavbarItem label="Layout" active={path === "layout"} />
            </Link>
            <Link to="publish">
              <MobileTopNavbarItem
                label="Publish"
                active={path === "publish"}
              />
            </Link>
            <Link to="exports">
              <MobileTopNavbarItem
                label="Exports"
                active={path === "exports"}
              />
            </Link>
            <Link to="questions">
              <MobileTopNavbarItem
                label="Questions"
                active={path === "questions"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </TypeContext.Provider>
  );
};
