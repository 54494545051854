import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  UIPage,
} from "@eventsquare/uikit";

export const EditionOrdersLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[7];

  return (
    <>
      <UIPage>
        <PageHeader title="Orders" />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>
            <Link to="tickets">
              <MobileTopNavbarItem
                label="Tickets"
                active={path === "tickets"}
              />
            </Link>
            <Link to="refunds">
              <MobileTopNavbarItem
                label="Refunds"
                active={path === "refunds"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </>
  );
};
