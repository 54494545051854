import styles from "./TypesTypeSwitch.module.scss";
import { Text } from "@eventsquare/uikit";

import { TypeType } from "@type/types";

interface TypesTypeSwitchProps {
  value: TypeType;
  handleTypeChange: (type: TypeType) => void;
}

export const TypesTypeSwitch = (props: TypesTypeSwitchProps) => {
  const { value, handleTypeChange } = props;
  return (
    <div className={styles.typeSwitch}>
      <div
        onClick={() => handleTypeChange("ticket")}
        className={styles.typeSwitch__option}
        style={{
          borderColor:
            value === "ticket"
              ? "var(--theme-color-primary)"
              : "var(--theme-form-border-color)",
        }}
      >
        <Text variant="h6">Ticket</Text>
        <Text noMargin variant="small">
          A ticket is a voucher that represents a reservation for an event, a
          flight, a concert, etc.
        </Text>
      </div>
      <div
        onClick={() => handleTypeChange("voucher")}
        className={styles.typeSwitch__option}
        style={{
          borderColor:
            value === "voucher"
              ? "var(--theme-color-primary)"
              : "var(--theme-form-border-color)",
        }}
      >
        <Text variant="h6">Voucher</Text>
        <Text noMargin variant="small">
          A vouchers represents something else 🥹
        </Text>
      </div>
    </div>
  );
};
