import styles from "./publish.module.scss";
import { useState } from "react";
import { Button, Container, Message, Text } from "@eventsquare/uikit";
import {
  Form,
  FormDatePicker,
  FormFieldset,
  FormGroup,
  FormLabel,
  FormRadioGroup,
} from "@eventsquare/uikit/forms";

import { useTypeContext } from "@context/typeContext";

import { TypeDetails } from "@type/types";

import { Api } from "@lib/api";
import { dateToSql } from "@lib/helpers";

interface TypePublishValues {
  published_option: "visible" | "not_visible" | "dates";
  published_from: Date | null;
  published_until: Date | null;
  sales_option: "visible" | "not_visible" | "dates";
  sales_start: Date | null;
  sales_end: Date | null;
}

const getInitialValues = (type: TypeDetails | null): TypePublishValues => {
  if (!type) return {} as TypePublishValues;
  const { published, sales } = type;
  const published_from = published.from ? new Date(published.from) : null;
  const published_until = published.until ? new Date(published.until) : null;
  const sales_start = sales.start ? new Date(sales.start) : null;
  const sales_end = sales.end ? new Date(sales.end) : null;

  const now = new Date();

  let published_option: "visible" | "not_visible" | "dates" =
    published_from && published_until
      ? "dates"
      : published_from
      ? "visible"
      : "not_visible";

  // if published_start is in the future, set option to dates
  if (published_from && published_from > now) {
    published_option = "dates";
  }

  let sales_option: "visible" | "not_visible" | "dates" =
    sales_start && sales_end
      ? "dates"
      : sales_start
      ? "visible"
      : "not_visible";

  // if sales_start is in the future, set option to dates
  if (sales_start && sales_start > now) {
    sales_option = "dates";
  }

  return {
    published_option,
    published_from,
    published_until,
    sales_option,
    sales_start,
    sales_end,
  };
};

export const EditionTypesPublish = () => {
  const { type, setType } = useTypeContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const initialValues = getInitialValues(type);

  const submitHandler = async (values: TypePublishValues) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await Api.put(`/types/${type!.id}`, {
        published: {
          from:
            values.published_option === "dates"
              ? values.published_from
                ? dateToSql(values.published_from)
                : ""
              : values.published_option === "visible"
              ? dateToSql(new Date(new Date().setHours(0, 0, 0, 0)))
              : "",
          until:
            values.published_option === "dates"
              ? values.published_until
                ? dateToSql(values.published_until)
                : ""
              : "",
        },
        sales: {
          start:
            values.sales_option === "dates"
              ? values.sales_start
                ? dateToSql(values.sales_start)
                : ""
              : values.sales_option === "visible"
              ? dateToSql(new Date(new Date().setHours(0, 0, 0, 0)))
              : "",
          end:
            values.sales_option === "dates"
              ? values.sales_end
                ? dateToSql(values.sales_end)
                : ""
              : "",
        },
      });
      setType(response.type);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  if (!type) return null;

  return (
    <Container>
      <Form initialValues={initialValues} onSubmit={submitHandler}>
        {(context) => {
          const { values } = context;
          return (
            <>
              {error && (
                <Message
                  alert
                  type="error"
                  message={error}
                  onClick={() => setError(null)}
                />
              )}
              {success && (
                <Message
                  alert
                  type="success"
                  message="Settings saved"
                  onClick={() => setSuccess(false)}
                />
              )}

              <FormFieldset disabled={loading}>
                <div className={styles.publish}>
                  <div className={styles.publish__published}>
                    <FormGroup>
                      <FormLabel>Visibility in store</FormLabel>
                      <Text variant="small">
                        Determine how and when the ticket is visible in the
                        store to your visitors
                      </Text>
                      <FormRadioGroup
                        name="published_option"
                        options={[
                          { value: "visible", label: "Always visible" },
                          { value: "not_visible", label: "Never visible" },
                          { value: "dates", label: "Pick your own dates" },
                        ]}
                      />
                    </FormGroup>
                    {values.published_option === "dates" && (
                      <>
                        <FormGroup>
                          <FormLabel htmlFor="published_from">From</FormLabel>
                          <FormDatePicker
                            name="published_from"
                            hideHours={false}
                            clearButton
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="publish_until">Until</FormLabel>
                          <FormDatePicker
                            name="publish_until"
                            hideHours={false}
                            clearButton
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                  <div className={styles.publish__sales}>
                    <FormGroup>
                      <FormLabel>Availability</FormLabel>
                      <Text variant="small">
                        Determine how and when the ticket is available for
                        purchase
                      </Text>
                      <FormRadioGroup
                        name="sales_option"
                        options={[
                          {
                            value: "visible",
                            label: "Always (Must be visible)",
                          },
                          { value: "not_visible", label: "Never visible" },
                          { value: "dates", label: "Pick your own dates" },
                        ]}
                      />
                    </FormGroup>
                    {values.sales_option === "dates" && (
                      <>
                        <FormGroup>
                          <FormLabel htmlFor="sales_start">From</FormLabel>
                          <FormDatePicker
                            name="sales_start"
                            hideHours={false}
                            clearButton
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="sales_end">Until</FormLabel>
                          <FormDatePicker
                            name="sales_end"
                            hideHours={false}
                            clearButton
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>
              </FormFieldset>
              <div>
                <Button type="submit" loading={loading}>
                  Save settings
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </Container>
  );
};
