import styles from "./dashboard.module.scss";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, Link, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Count,
  Empty,
  PageLoader,
  ProgressBar,
  SortableTableBody,
  SortableTableBodyInner,
  SortableTableRow,
  SortableTableRowDrag,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tile,
  Toggle,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { RiPencilLine, RiTicketLine } from "@remixicon/react";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { useShowContext } from "@context/showContext";

import { Api } from "@lib/api";
import { getShowProgressBarColor } from "@lib/shows";
import { formatPrice, stopPropagation } from "@lib/helpers";

import { Dashboard } from "@components/DashBoard/Dashboard";
import { EditTypeInShowModal } from "@components/modals/EditTypeInShowModal/EditTypeInShowModal";

import { Type, TypeInShow } from "@type/types";
import { ShowListType } from "@type/shows";

// TODO : Define styling of Colgroup

const Colgroup = () => {
  return (
    <colgroup className={styles.colgroup}>
      <col className={styles.colName} />
      <col className={styles.colType} />
      <col className={styles.colPrice} />
      <col className={styles.colProgress} />
      <col className={styles.colActive} />
      <col className={styles.colEdit} />
      <col className={styles.colDraghandler} />
    </colgroup>
  );
};

export const EditionShowsDashboard = () => {
  const { show } = useShowContext();
  const { eventId, editionId, showId } = useParams();
  const { account, user } = useAuth();

  const [types, setTypes] = useState<ShowListType[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [draggingType, setDraggingType] = useState<ShowListType | undefined>(
    undefined
  );
  const [editingType, setEditingType] = useState<ShowListType | undefined>(
    undefined
  );

  const fetchTypes = useCallback(async () => {
    try {
      //setLoading(true);
      // Fetch all types for the edition
      const { types } = await Api.get(`/editions/${editionId}/types`);
      // Fetch all types active in the show
      const { types: typesInShow } = await Api.get(`/shows/${showId}/types`);

      // merge the two arrays to ShowListTypes
      const typesArray = types
        .map((type: Type, index: number) => {
          // This offset is used to add to the index for the position of types not (yet) in the show
          const offset = typesInShow.length;
          // Find the type in the typesInShow array
          const foundIndex = typesInShow.findIndex(
            (typeInShow: TypeInShow) => typeInShow.id === type.id
          );
          // Check if the type is found in the typesInShow array
          const isfound = foundIndex !== -1;
          // Return the type with the correct values
          return {
            ...type,
            active: isfound,
            //issued: isfound ? typesInShow[foundIndex].issued : 0,
            limit: isfound ? typesInShow[foundIndex].limit : null,
            position: isfound ? foundIndex : offset + index,
            price_override: isfound
              ? typesInShow[foundIndex].price_override
              : null,
            status: isfound ? typesInShow[foundIndex].status : type.status,
            stock: isfound ? typesInShow[foundIndex].stock : null,
          };
        })
        .sort((a: ShowListType, b: ShowListType) => a.position - b.position);
      // Set the types

      setTypes(typesArray);
    } catch (error) {
      // TODO : Error handling
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [editionId, showId]);

  useEffect(() => {
    fetchTypes();
  }, [fetchTypes]);

  const onDragStart = (e: DragStartEvent) => {
    const { active } = e;
    const t = types?.find(({ id }) => id === active.id);
    setDraggingType(t ?? undefined);
  };
  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    setTypes((list) => {
      if (!list) return [];
      const oldIndex = list.findIndex(({ id }) => id === active.id);
      const newIndex = list.findIndex(({ id }) => id === over?.id);
      const arr = arrayMove(list, oldIndex, newIndex);
      updateSorting(arr);
      return arr;
    });
  };
  const onDragCancel = () => {
    setDraggingType(undefined);
  };

  const updateSorting = async (sortedArr: ShowListType[]) => {
    try {
      const sortedIds = sortedArr
        .filter((type) => type.active)
        .map((type) => type.id);
      await Api.post(`/shows/${showId}/types/sort`, {
        types: sortedIds,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getProgressBarColor = (type: ShowListType) => {
    const { stock, status, limit } = type;
    if (show && show.status === "not_available") return "disabled";
    if (limit && stock.issued > limit) return "error";
    if (stock.issued > stock.total) return "error";
    if (status === "soldout") return "info";
    if (status === "low") return "warning";
    if (status === "not_available") return "disabled";
    return "success";
  };

  const toggleType = async (type: ShowListType) => {
    if (!types) return;
    // Make a copy of the types array in case of error
    const typesBeforeUpdate = types;
    // Determine the action based on the active state of the type
    const action = type.active ? "delete" : "add";
    // Update the types array to reflect the change - Optimistic update
    const updatedTypes = types.map((t) => {
      if (t.id === type.id)
        return {
          ...t,
          active: !type.active,
          stock:
            action === "delete"
              ? null
              : {
                  available: 0,
                  issued: 0,
                  pending: 0,
                  total: 0,
                },
        };
      return t;
    });
    setTypes(updatedTypes as ShowListType[]);

    try {
      // Make the API call to add or remove the type from the show
      if (action === "delete") {
        await Api.delete(`/shows/${showId}/types`, {
          type: type.id,
        });
        // If the type is removed from the show, end function
        return;
      }

      if (action === "add") {
        await Api.post(`/shows/${showId}/types`, {
          type: type.id,
        });

        // If the type is added to the show, update the stock of the type
        // Fetch the types in the show with stock of updated type
        await Api.get(`/shows/${showId}/types`);
      }
    } catch (error) {
      console.error(error);
      // If there is an error, reset the types array to the state before the update
      // TODO : Error handling - Show error message to user
      setTypes(typesBeforeUpdate);
    }
  };

  if (!show) return null;

  return (
    <>
      <UIPage>
        <Container>
          <Dashboard
            barColor={getShowProgressBarColor(show)}
            issued={show.attendees.issued}
            pending={show.attendees.pending}
            total={show.attendees.limit}
            guestlistLink={`/events/${eventId}/editions/${editionId}/reports/guestlist?${createSearchParams(
              {
                shows: [show.id],
              }
            )}`}
          >
            <Tile
              title="Pending"
              value={<Count end={show.attendees.pending} />}
            />
            <Tile
              title="Available"
              value={<Count end={show.attendees.available} />}
            />
            <Tile title="Limit" value={<Count end={show.attendees.limit} />} />
          </Dashboard>

          {loading && <PageLoader />}
          {!loading && types && types.length === 0 && (
            <Empty
              title={"No tickets found"}
              lead={"Create a ticket to make this available in your show"}
              icon={<RiTicketLine />}
            >
              <ButtonGroup noMargin>
                <Link
                  to={`/events/${eventId}/editions/${editionId}/tickets/create`}
                >
                  <Button>Create a ticket</Button>
                </Link>
              </ButtonGroup>
            </Empty>
          )}
          {!loading && types && types.length > 0 && (
            <Table scrollable>
              <Colgroup />
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell hideMobile>Type</TableHeadCell>
                  <TableHeadCell hideMobile textAlign="right">
                    Price
                  </TableHeadCell>
                  <TableHeadCell>Progress</TableHeadCell>
                  <TableHeadCell>Active</TableHeadCell>
                  <TableHeadCell>Edit</TableHeadCell>
                  <TableHeadCell dragHandler />
                </TableRow>
              </TableHead>
              <SortableTableBody
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
              >
                <SortableTableBodyInner items={types}>
                  {types.map((type, index) => {
                    return (
                      <SortableTableRow
                        id={type.id}
                        color={type.branding.color}
                        key={index}
                        alignHandler="end"
                      >
                        <TableCell>
                          <div>
                            <strong>{type.name}</strong>
                          </div>
                        </TableCell>
                        <TableCell hideMobile>{type.type}</TableCell>
                        <TableCell hideMobile textAlign="right">
                          {type.price_override !== null
                            ? formatPrice(
                                type.price_override,
                                account!.currency,
                                user!.language
                              )
                            : formatPrice(
                                type.price,
                                account!.currency,
                                user!.language
                              )}
                        </TableCell>
                        <TableCell>
                          {type.active && type.stock && (
                            <ProgressBar
                              index={index}
                              total={
                                type.limit !== null
                                  ? type.limit
                                  : type.stock.total
                              }
                              value={type.stock.issued}
                              // pending={type.stock.pending}
                              color={getProgressBarColor(type)}
                              badge={
                                show.status === "not_available"
                                  ? "not_available"
                                  : type.status
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell textAlign="right">
                          <div
                            className={styles.toggleWrapper}
                            onClick={stopPropagation}
                          >
                            <Toggle
                              checked={!!type.active}
                              onChange={() => toggleType(type)}
                              size="small"
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            size="tiny"
                            onClick={() => {
                              setEditingType(type);
                            }}
                            variant="outline"
                            icon
                            disabled={!type.active}
                          >
                            <RiPencilLine />
                          </Button>
                        </TableCell>
                      </SortableTableRow>
                    );
                  })}
                </SortableTableBodyInner>
                <SortableTableRowDrag
                  isDragging={!!draggingType}
                  colgroup={<Colgroup />}
                >
                  {draggingType && (
                    <>
                      <TableCell>
                        <div>
                          <strong>{draggingType.name}</strong>
                        </div>
                      </TableCell>
                      <TableCell hideMobile>{draggingType.type}</TableCell>
                      <TableCell hideMobile textAlign="right">
                        {draggingType.price_override !== null
                          ? formatPrice(
                              draggingType.price_override,
                              account!.currency,
                              user!.language
                            )
                          : formatPrice(
                              draggingType.price,
                              account!.currency,
                              user!.language
                            )}
                      </TableCell>
                      <TableCell>
                        {draggingType.active && draggingType.stock && (
                          <ProgressBar
                            index={0}
                            total={
                              draggingType.limit !== null
                                ? draggingType.limit
                                : draggingType.stock.total
                            }
                            value={draggingType.stock.issued}
                            pending={draggingType.stock.pending}
                            color={getProgressBarColor(draggingType)}
                            badge={
                              show.status === "not_available"
                                ? "not_available"
                                : draggingType.status
                            }
                            animate={false}
                          />
                        )}
                      </TableCell>
                      <TableCell textAlign="right">
                        <div
                          className={styles.toggleWrapper}
                          onClick={stopPropagation}
                        >
                          <Toggle
                            checked={!!draggingType.active}
                            onChange={() => {}}
                            size="small"
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="tiny"
                          onClick={() => {}}
                          variant="outline"
                          icon
                        >
                          <RiPencilLine />
                        </Button>
                      </TableCell>
                    </>
                  )}
                </SortableTableRowDrag>
              </SortableTableBody>
            </Table>
          )}
        </Container>
        <EditTypeInShowModal
          type={editingType}
          setType={setEditingType}
          showId={show.id}
          afterUpdate={fetchTypes}
        />
      </UIPage>
    </>
  );
};
