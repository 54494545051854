import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  NavBarHeading,
  NavBarList,
  NavBarListItem,
  UIContentNavbar,
  UIPage,
} from "@eventsquare/uikit";
import {
  RiArticleLine,
  RiBankLine,
  RiCashLine,
  RiDashboardLine,
  RiDownloadCloud2Line,
  RiGroup3Line,
  RiLineChartLine,
  RiQrCodeLine,
  RiQuestionLine,
  RiReceiptLine,
  RiSettings4Line,
  RiSwapBoxLine,
} from "@remixicon/react";

export const SettingsLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  // const path2 = location.pathname.split("/")[3];

  // TODO : Make TopNavbar only visible on mobile
  // TODO : Make SideNavbar available on desktop

  return (
    <>
      <UIContentNavbar>
        <NavBarHeading title={"Account"} />
        <NavBarList>
          <Link to="">
            <NavBarListItem
              label="Dashboard"
              active={path === undefined}
              icon={RiDashboardLine}
            />
          </Link>
          <Link to="users">
            <NavBarListItem
              label="Users"
              active={path === "users"}
              icon={RiGroup3Line}
            />
          </Link>
          {/* <Link to="support">
                        <NavBarListItem
                            label="Support"
                            active={path === "email"}
                            icon={RiInformationLine}
                        />
                    </Link> */}
          <Link to="integrations">
            <NavBarListItem
              label="Integrations"
              active={path === "integrations"}
              icon={RiSwapBoxLine}
            />
          </Link>
          <Link to="questions">
            <NavBarListItem
              label="Questions"
              active={path === "questions"}
              icon={RiQuestionLine}
            />
          </Link>
          <Link to="downloads">
            <NavBarListItem
              label="Downloads"
              active={path === "downloads"}
              icon={RiDownloadCloud2Line}
            />
          </Link>
          <Link to="reservations">
            <NavBarListItem
              label="Reservations"
              active={path === "reservations"}
              icon={RiQrCodeLine}
            />
          </Link>
          <Link to="account">
            <NavBarListItem
              label="Settings"
              active={path === "account"}
              icon={RiSettings4Line}
            />
          </Link>
        </NavBarList>
        <NavBarHeading title={"Financial"} />
        <NavBarList>
          <Link to="invoicing">
            <NavBarListItem
              label="Billing"
              active={path === "invoicing"}
              icon={RiArticleLine}
            />
          </Link>
          <Link to="bankaccounts">
            <NavBarListItem
              label="Bankaccounts"
              active={path === "bankaccounts"}
              icon={RiBankLine}
            />
          </Link>
          <Link to="payouts">
            <NavBarListItem
              label="Payouts"
              active={path === "payouts"}
              icon={RiCashLine}
            />
          </Link>
          <Link to="invoices">
            <NavBarListItem
              label="Invoices"
              active={path === "invoices"}
              icon={RiReceiptLine}
            />
          </Link>
          <Link to="salesinvoices">
            <NavBarListItem
              label="Salesinvoices"
              active={path === "salesinvoices"}
              icon={RiLineChartLine}
            />
          </Link>
        </NavBarList>
        {/* <NavBarHeading title={"Personal"} />
                <NavBarList>
                    <Link to="me">
                        <NavBarListItem
                            label="Profile"
                            active={path === "me" && path2 === undefined}
                            icon={RiProfileLine}
                        />
                    </Link>
                    <Link to="me/preferences">
                        <NavBarListItem
                            label="Preferences"
                            active={path === "me" && path2 === "preferences"}
                            icon={RiUserHeartLine}
                        />
                    </Link>
                </NavBarList> */}
      </UIContentNavbar>
      <UIPage>
        <Container>
          <MobileTopNavbar hideTablet>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>

            <Link to="users">
              <MobileTopNavbarItem label="Users" active={path === "users"} />
            </Link>
            {/* <Link to="support">
              <MobileTopNavbarItem label="Support" active={path === "email"} />
            </Link>  */}
            <Link to="integrations">
              <MobileTopNavbarItem
                label="Integrations"
                active={path === "integrations"}
              />
            </Link>
            <Link to="questions">
              <MobileTopNavbarItem
                label="Questions"
                active={path === "questions"}
              />
            </Link>
            <Link to="downloads">
              <MobileTopNavbarItem
                label="Downloads"
                active={path === "downloads"}
              />
            </Link>
            <Link to="reservations">
              <MobileTopNavbarItem
                label="Reservations"
                active={path === "reservations"}
              />
            </Link>
            <Link to="account">
              <MobileTopNavbarItem
                label="Account"
                active={path === "account"}
              />
            </Link>
            <Link to="invoicing">
              <MobileTopNavbarItem
                label="Invoicing"
                active={path === "invoicing"}
              />
            </Link>
            <Link to="bankaccounts">
              <MobileTopNavbarItem
                label="Bankaccounts"
                active={path === "bankaccounts"}
              />
            </Link>
            <Link to="payouts">
              <MobileTopNavbarItem
                label="Payouts"
                active={path === "payouts"}
              />
            </Link>
            <Link to="invoices">
              <MobileTopNavbarItem
                label="Invoices"
                active={path === "invoices"}
              />
            </Link>
            <Link to="salesinvoices">
              <MobileTopNavbarItem
                label="Salesinvoices"
                active={path === "salesinvoices"}
              />
            </Link>

            {/* <Link to="me">
              <MobileTopNavbarItem
                label="Profile"
                active={path === "me" && path2 === undefined}
              />
            </Link>
            <Link to="me/preferences">
              <MobileTopNavbarItem
                label="Preferences"
                active={path === "me" && path2 === "preferences"}
              />
            </Link> */}
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </>
  );
};
