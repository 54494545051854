import { useState } from "react";
import * as yup from "yup";
import styles from "./settings.module.scss";
import {
  Button,
  ButtonGroup,
  Container,
  Message,
  Text,
} from "@eventsquare/uikit";
import {
  Form,
  FormCheckbox,
  FormDatePicker,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
} from "@eventsquare/uikit/forms";
import { RiDeleteBinLine } from "@remixicon/react";

import { useShowContext } from "@context/showContext";

import { Api } from "@lib/api";
import { dateToSql } from "@lib/helpers";

import { DeleteShowModal } from "@components/modals/DeleteShowModal/DeleteShowModal";

import { Show } from "@type/shows";

interface ShowSettingsValues {
  name: string | null;
  attendees_limit: number | "";
  date_start: Date | null;
  date_end: Date | null;
  date_doors: Date | null;
  date_hide_hours: boolean;
  active: boolean;
}

export const EditionShowsSettings = () => {
  const { show, setShow, scrollUp } = useShowContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | string[] | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [showToDelete, setShowToDelete] = useState<Show | null>(null);
  const [deleteError, setDeleteError] = useState<string | string[] | undefined>(
    undefined
  );

  const intitialValues: ShowSettingsValues = {
    name: show?.name ?? "",
    attendees_limit: show!.attendees.limit ?? "",
    date_start: show!.date.start ? new Date(show!.date.start) : null,
    date_end: show!.date.end ? new Date(show!.date.end) : null,
    date_doors: show!.date.doors ? new Date(show!.date.doors) : null,
    date_hide_hours: show!.date.hide_hours ?? false,
    active: !!show!.active,
  };

  const yupSchema = yup.object().shape({
    name: yup.string().nullable(),
    attendees_limit: yup.lazy((value) => {
      if (value === "") {
        return yup.string().nullable();
      } else {
        return yup.number().integer().positive().nullable();
      }
    }),
    date_start: yup.date().nullable(),
    date_end: yup
      .date()
      .nullable()
      .when(
        "date_start",
        (
          date_start: unknown[],
          schema: yup.DateSchema<Date | null | undefined>
        ) => {
          const [dateStart] = date_start;
          return dateStart instanceof Date
            ? schema.min(dateStart, "End date must be after start date")
            : schema;
        }
      ),
    date_doors: yup
      .date()
      .nullable()
      .when(
        "date_start",
        (
          date_start: unknown[],
          schema: yup.DateSchema<Date | null | undefined>
        ) => {
          const [dateStart] = date_start;
          return dateStart instanceof Date
            ? schema.max(dateStart, "Doors must be before start date")
            : schema;
        }
      ),
    date_hide_hours: yup.boolean(),
    active: yup.boolean(),
  });

  const handleSubmit = async (values: ShowSettingsValues) => {
    if (loading) return;
    setLoading(true);
    try {
      const { show: updatedShow } = await Api.put(`/shows/${show!.id}`, {
        name: values.name,
        attendees: {
          limit: values.attendees_limit === "" ? null : values.attendees_limit,
        },
        date_start: values.date_start ? dateToSql(values.date_start) : "",
        date_end: values.date_end ? dateToSql(values.date_end) : "",
        date_doors: values.date_doors ? dateToSql(values.date_doors) : "",
        date_hide_hours: values.date_hide_hours,
        active: values.active,
      });
      setShow(updatedShow);
      setSuccess(true);
    } catch (error) {
      // TODO: handle error
      console.log(error);
      setError("An error occurred while updating the show");
    } finally {
      scrollUp();
      setLoading(false);
    }
  };

  const checkDeleteShow = async () => {
    if (!show) return;

    if (show.attendees.issued > 0 || show.attendees.pending > 0) {
      setDeleteError(
        "You cannot delete a show that has issued or pending tickets"
      );
    } else {
      setShowToDelete(show);
    }
  };

  return (
    <>
      <Container>
        <div className={styles.settings__form}>
          <Form
            initialValues={intitialValues}
            validationSchema={yupSchema}
            onSubmit={handleSubmit}
            maxWidth={800}
          >
            {(context) => {
              const { values } = context;
              return (
                <>
                  {error && (
                    <Message
                      type="error"
                      message={error}
                      onClick={() => setError(undefined)}
                    />
                  )}
                  {success && (
                    <Message
                      alert
                      type="success"
                      message="Settings saved"
                      onClick={() => setSuccess(false)}
                    />
                  )}
                  <FormFieldset disabled={loading}>
                    <FormGroup>
                      <FormLabel htmlFor="date_start">Date start</FormLabel>
                      <FormDatePicker
                        name="date_start"
                        hideHours={values.date_hide_hours as boolean}
                        maxDate={values.date_end as unknown as Date | undefined}
                        clearButton
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="date_end">Date end</FormLabel>
                      <FormDatePicker
                        name="date_end"
                        hideHours={values.date_hide_hours as boolean}
                        minDate={
                          values.date_start as unknown as Date | undefined
                        }
                        clearButton
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="date_doors">Date doors</FormLabel>
                      <FormDatePicker
                        name="date_doors"
                        hideHours={values.date_hide_hours as boolean}
                        maxDate={
                          values.date_start as unknown as Date | undefined
                        }
                        clearButton
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox name="date_hide_hours" label="Hide hours" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="name" helperText="Optional">
                        Name
                      </FormLabel>
                      <FormInput name="name" />
                      <Text variant="small" noMargin>
                        You can give a name if you would like to show something
                        other than the date
                      </Text>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="attendees_limit">Attendees</FormLabel>
                      <FormInput
                        name="attendees_limit"
                        type="number"
                        placeholder="Unlimited"
                        suffix="attendees"
                      />
                      <Text variant="small" noMargin>
                        Set a limit for the number of attendees
                      </Text>
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox name="active" label="Active show" />
                    </FormGroup>
                  </FormFieldset>
                  <div>
                    <Button type="submit" loading={loading}>
                      Update show
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
        {/* TODO : Should we show the danger zone if not possible to delete? e.g. if show.stock.issued or show.stock.pending */}
        <div className={styles.settings__dangerzone}>
          {deleteError && (
            <Message
              type="error"
              message={deleteError}
              onClick={() => setDeleteError(undefined)}
            />
          )}
          <Message
            icon={<RiDeleteBinLine />}
            type="error"
            title="Remove show"
            message="This action cannot be undone. Are you sure you want to remove this show?"
            variant="outline"
            animate={false}
          >
            <ButtonGroup noMargin>
              <Button type="button" color="error" onClick={checkDeleteShow}>
                Delete
              </Button>
            </ButtonGroup>
          </Message>
        </div>
      </Container>
      <DeleteShowModal
        show={showToDelete}
        setShow={setShowToDelete}
        setError={setDeleteError}
      />
    </>
  );
};
