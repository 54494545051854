import { useState } from "react";
import { Modal, Text, ModalButtons, Button } from "@eventsquare/uikit";
import { RiDeleteBinLine } from "@remixicon/react";

import { Api } from "@lib/api";

import { Question } from "@type/questions";

interface DeleteQuestionModalProps {
  question: Question | undefined;
  cancelDelete: () => void;
  afterDelete: () => void;
}

export const DeleteQuestionModal = (props: DeleteQuestionModalProps) => {
  const { question, cancelDelete, afterDelete } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const deleteQuestion = async () => {
    if (!question) return;
    setLoading(true);
    try {
      await Api.delete(`/questions/${question.id}`);
      afterDelete();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const closeModal = () => {
    if (loading) return;
    cancelDelete();
  };

  return (
    <Modal
      isVisible={!!question}
      closeModal={closeModal}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div>
        <Text variant="h3" color="error">
          Delete question
        </Text>
        <Text variant="lead">
          Are you sure you want to delete this question? This action cannot be
          undone.
        </Text>

        <ModalButtons>
          <Button
            block
            color="error"
            onClick={deleteQuestion}
            loading={loading}
          >
            Delete question
          </Button>
          <Button
            block
            variant="link"
            color="subtle"
            disabled={loading}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
