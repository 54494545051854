import { Container, PageHeader, UIPage, Text } from "@eventsquare/uikit";

interface DummyPageProps {
  title: string;
}

export const DummyPage = (props: DummyPageProps) => {
  const { title } = props;

  return (
    <UIPage>
      <PageHeader title={title} />
      <Container>
        <Text variant="p">This is the page about {title}</Text>
      </Container>
    </UIPage>
  );
};
