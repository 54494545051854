import styles from "./settings.module.scss";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Loader,
  Message,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import * as yup from "yup";
import {
  Form,
  FormCheckbox,
  FormDatePicker,
  FormFieldset,
  FormGroup,
  FormInput,
  FormLabel,
  FormMarkdown,
  FormTextArea,
} from "@eventsquare/uikit/forms";
import { RiDeleteBinLine, RiUserLine } from "@remixicon/react";

import { Api } from "@lib/api";
import { dateToSql, formatPrice, getCurrencySymbol } from "@lib/helpers";

import { useTypeContext } from "@context/typeContext";
import { TypesTypeSwitch } from "@components/TypesTypeSwitch/TypesTypeSwitch";
import { DeleteTypeModal } from "@components/modals/DeleteTypeModal/DeleteTypeModal";

import { TypeDetails, TypeType } from "@type/types";

interface TypeSettingsValues {
  type: TypeType;
  name: string;
  description: string;
  ticket_information: string;
  price: number | null;
  vat: number | null;
  fee_kickback: number | null;
  customer_service_fee: boolean;
  stock: number | null;
  min_pp: number;
  max_pp: number;
  attendees: number;
  attendees_split: boolean;
  dont_inherit_name: boolean;
  stock_public: boolean;
  date_start: Date | undefined;
  date_end: Date | undefined;
  date_doors: Date | undefined;
  date_hide_hours: boolean;
}

export const EditionTypesSettings = () => {
  const { type, setType, scrollUp } = useTypeContext();

  const { account, user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [typeToDelete, setTypeToDelete] = useState<TypeDetails | undefined>(
    undefined
  );
  const [deleteError, setDeleteError] = useState<string | string[] | undefined>(
    undefined
  );

  const initialValues = {
    type: type!.type,
    name: type!.name,
    description: type!.description || "",
    ticket_information: type!.ticket_information || "",
    price: type!.price,
    vat: type!.vat,
    fee_kickback: type!.fee_kickback,
    customer_service_fee: type!.customer.servicefees ? true : false,
    stock: type!.stock.generator.total,
    min_pp: type!.min_pp || 1,
    max_pp: type!.max_pp || 10,
    attendees: type!.attendees,
    attendees_split: type!.attendees_split ? true : false,
    dont_inherit_name: type!.inherit_name ? false : true,
    stock_public: type!.stock_public ? true : false,
    date_start: type!.date.start ? new Date(type!.date.start) : undefined,
    date_end: type!.date.end ? new Date(type!.date.end) : undefined,
    date_doors: type!.date.doors ? new Date(type!.date.doors) : undefined,
    date_hide_hours: type!.date.hide_hours ? true : false,
  } as TypeSettingsValues;

  const yupSchema = yup.object().shape({
    type: yup
      .string()
      .required("Type is required")
      .oneOf(["ticket", "voucher", "virtual"], "Invalid type"),
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Max 100 characters"),
    description: yup.string(),
    ticket_information: yup.string().max(480, "Max 480 characters"),
    price: yup
      .number()
      .min(0, "Price must be positive")
      .max(10000, "Price must be less than 10.000"),
    vat: yup
      .number()
      .min(0, "VAT must be positive")
      .max(100, "VAT can be maximum 100%"),
    fee_kickback: yup
      .number()
      .min(0, "Fee kickback must be positive")
      .max(1000, "Fee kickback can be maximum 1000"),
    customer_service_fee: yup.boolean(),
    stock: yup
      .number()
      .integer()
      .min(0, "Stock must be positive")
      .max(10000, "Stock can be maximum 10000")
      .nullable(),
    min_pp: yup
      .number()
      .integer()
      .positive()
      .min(1, "Min. number of tickets must be at least 1")
      .max(100, "Min. tickets can be maximum 100"),

    max_pp: yup
      .number()
      .integer()
      .positive()
      .min(1, "Max. number of tickets must be at least 1")
      .max(2500, "Max. tickets can be maximum 100")
      .when("min_pp", (min_pp: unknown[], schema: yup.NumberSchema) => {
        const [minPP] = min_pp;
        return typeof minPP === "number"
          ? schema.min(minPP, "Max. tickets must be more than min. tickets")
          : schema;
      }),
    attendees: yup
      .number()
      .integer()
      .positive()
      .when("type", (type: unknown[], schema: yup.NumberSchema) => {
        const [typeValue] = type;
        return typeValue === "ticket"
          ? schema.min(1, "Group ticket must be at least 1")
          : schema;
      }),
    date_start: yup.date().nullable(),
    date_end: yup
      .date()
      .nullable()
      .when(
        "date_start",
        (
          date_start: unknown[],
          schema: yup.DateSchema<Date | null | undefined>
        ) => {
          const [dateStart] = date_start;
          return dateStart instanceof Date
            ? schema.min(dateStart, "End date must be after start date")
            : schema;
        }
      ),
    date_doors: yup
      .date()
      .nullable()
      .when(
        "date_start",
        (
          date_start: unknown[],
          schema: yup.DateSchema<Date | null | undefined>
        ) => {
          const [dateStart] = date_start;
          return dateStart instanceof Date
            ? schema.max(dateStart, "Doors must be before start date")
            : schema;
        }
      ),
    date_hide_hours: yup.boolean(),
  });

  const handleSubmit = async (values: TypeSettingsValues) => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);
    try {
      const response = await Api.put(`/types/${type!.id}`, {
        type: values.type,
        name: values.name,
        stock: values.stock,
        price: values.price,
        vat: values.vat,
        fee_kickback: values.fee_kickback || 0,
        attendees: values.attendees,
        attendees_split: values.attendees_split ? 1 : 0,
        description: values.description,
        ticket_information:
          values.ticket_information.trim().length > 0
            ? values.ticket_information
            : null,
        customer: {
          servicefees: values.customer_service_fee ? 1 : 0,
        },
        stock_public: values.stock_public,
        min_pp: values.min_pp,
        max_pp: values.max_pp,
        inherit_name: values.dont_inherit_name ? 0 : 1, // This has to switch for the API
        date: {
          start: values.date_start ? dateToSql(values.date_start) : "",
          end: values.date_end ? dateToSql(values.date_end) : "",
          doors: values.date_doors ? dateToSql(values.date_doors) : "",
          hide_hours: true,
        },
      });
      setType(response.type);
      setSuccess(true);
    } catch (error) {
      setError("Something went wrong. Please try again later.");
      console.error(error);
    } finally {
      setLoading(false);
      scrollUp();
    }
  };

  const checkDeleteType = () => {
    if (!type) return;

    if (type.stock.issued > 0 || type.stock.pending > 0) {
      setDeleteError("Cannot delete type with issued or pending tickets");
      return;
    } else {
      setTypeToDelete(type);
    }
  };

  if (!account || !user || !type) return null;

  return (
    <>
      <Container>
        <div className={styles.settings__form}>
          <Form
            initialValues={initialValues}
            validationSchema={yupSchema}
            onSubmit={handleSubmit}
            maxWidth={800}
          >
            {(context) => {
              const { setFieldValue, values } = context;
              const handleTypeChange = (type: "ticket" | "voucher") => {
                setFieldValue("type", type);
              };

              return (
                <>
                  {error && (
                    <Message
                      alert
                      type="error"
                      message={error}
                      onClick={() => setError(undefined)}
                    />
                  )}
                  {success && (
                    <Message
                      alert
                      type="success"
                      message="Settings saved"
                      onClick={() => setSuccess(false)}
                    />
                  )}
                  <FormFieldset disabled={loading}>
                    <FormGroup>
                      <TypesTypeSwitch
                        value={values.type as TypeType}
                        handleTypeChange={handleTypeChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <FormInput name="name" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="description">
                        Additional info store
                      </FormLabel>
                      <FormMarkdown name="description" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="ticket_information">
                        Addition info on ticket
                      </FormLabel>
                      <FormTextArea name="ticket_information" />
                    </FormGroup>
                    <div className={styles.settings__pricing}>
                      <FormGroup>
                        <FormLabel htmlFor="price">Price</FormLabel>
                        <FormInput
                          name="price"
                          type="number"
                          prefix={getCurrencySymbol(
                            account.currency,
                            user.language
                          )}
                          step={0.01}
                          min={0}
                          max={10000}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel htmlFor="vat">VAT rate</FormLabel>
                        <FormInput
                          name="vat"
                          type="number"
                          prefix="%"
                          step={0.01}
                          min={0}
                          max={100}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <FormLabel htmlFor="fee_kickback">Kickback fee</FormLabel>
                      <FormInput
                        name="fee_kickback"
                        type="number"
                        prefix="€"
                        step={0.01}
                        min={0}
                        max={1000}
                      />
                      <Text variant="small">
                        Additional surcharge included in the service fee
                      </Text>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Service fee</FormLabel>
                      <FormCheckbox
                        name="customer_service_fee"
                        label="I want to charge a service fee to the ticket buyer"
                      />
                    </FormGroup>
                    <TypePricingBox
                      typeId={type!.id}
                      price={values.price as number}
                      fee_kickback={values.fee_kickback as number}
                      vat={values.vat as number}
                      servicefee={values.customer_service_fee as boolean}
                    />
                    <FormGroup>
                      <FormLabel htmlFor="stock">
                        Total number of tickets
                      </FormLabel>
                      <FormInput
                        name="stock"
                        type="number"
                        min={0}
                        max={100000}
                        step={1}
                      />
                    </FormGroup>
                    <div className={styles.settings__pp}>
                      <FormGroup>
                        <FormLabel htmlFor="min_pp">
                          Min. quantity per order
                        </FormLabel>
                        <FormInput
                          name="min_pp"
                          type="number"
                          step={1}
                          min={1}
                          max={100}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel htmlFor="max_pp">
                          Max. quantity per order
                        </FormLabel>
                        <FormInput
                          name="max_pp"
                          type="number"
                          step={1}
                          min={values.min_pp as number}
                          max={2500}
                        />
                      </FormGroup>
                    </div>

                    {values.type === "ticket" && (
                      <>
                        {(values.attendees as number) > 1 && (
                          <Message
                            alert
                            type="info"
                            message={[
                              `This ticket is valid for ${
                                values.attendees
                              } attendees and
                    the customer will receive ${
                      values.attendees_split
                        ? values.attendees + " tickets"
                        : "1 ticket"
                    }.`,
                            ]}
                          />
                        )}
                        <div className={styles.settings__attendees}>
                          <TypeAttendees
                            name="attendees"
                            value={values.attendees as number}
                            setFieldValue={setFieldValue}
                          />
                          {(values.attendees as number) > 1 && (
                            <FormGroup>
                              <FormLabel>Split tickets</FormLabel>
                              <FormCheckbox
                                name="attendees_split"
                                label="Create a separate ticket for each person"
                              />
                            </FormGroup>
                          )}
                        </div>
                      </>
                    )}
                    <FormGroup>
                      <FormLabel>Options</FormLabel>
                      <div className={styles.settings__options}>
                        {values.type === "ticket" && (
                          <div>
                            <FormCheckbox
                              name="inherit_name"
                              label="Customer must enter data per ticket"
                            />
                          </div>
                        )}
                        <div>
                          <FormCheckbox
                            name="stock_public"
                            label="Show stock in the ticket shop"
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <Text variant="small">
                      Other date or time than your event? Here you can set
                      custom dates and times for this ticket
                    </Text>
                    <FormGroup>
                      <FormLabel htmlFor="date_start">
                        Custom start date
                      </FormLabel>
                      <FormDatePicker
                        name="date_start"
                        hideHours={!!values.date_hide_hours}
                        clearButton
                        maxDate={values.date_end as unknown as Date | undefined}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="date_end">Custom end date</FormLabel>
                      <FormDatePicker
                        name="date_end"
                        hideHours={!!values.date_hide_hours}
                        clearButton
                        minDate={
                          values.date_start as unknown as Date | undefined
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="date_doors">
                        Custom doors date
                      </FormLabel>
                      <FormDatePicker
                        name="date_doors"
                        hideHours={!!values.date_hide_hours}
                        clearButton
                        //maxDate={values.date_start as unknown as Date | undefined}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox name="date_hide_hours" label="Hide hours" />
                    </FormGroup>
                  </FormFieldset>
                  <div>
                    <Button type="submit" loading={loading}>
                      Save
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
        <div className={styles.settings__dangerzone}>
          {deleteError && (
            <Message
              type="error"
              message={deleteError}
              onClick={() => setDeleteError(undefined)}
            />
          )}
          <Message
            icon={<RiDeleteBinLine />}
            type="error"
            title="Remove show"
            message="This action cannot be undone. Are you sure you want to remove this show?"
            variant="outline"
            animate={false}
          >
            <ButtonGroup noMargin>
              <Button type="button" color="error" onClick={checkDeleteType}>
                Delete
              </Button>
            </ButtonGroup>
          </Message>
        </div>
      </Container>
      <DeleteTypeModal
        type={typeToDelete}
        setType={setTypeToDelete}
        setError={setDeleteError}
      />
    </>
  );
};

interface TypePricingBoxProps {
  typeId: string;
  price: number;
  fee_kickback: number | null;
  vat: number | null;
  servicefee: boolean;
}

const TypePricingBox = (props: TypePricingBoxProps) => {
  const { typeId, price, fee_kickback, servicefee, vat } = props;
  const { account, user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number | null>(null);
  const [expenses, setExpenses] = useState<number | null>(null);
  const [revenue, setRevenue] = useState<number | null>(null);

  const fetchPricing = useCallback(async () => {
    if (!typeId) return;
    setLoading(true);
    try {
      const { pricing } = await Api.post(`/types/${typeId}/actions`, {
        type: "simulatePricing",
        price: price,
        fee_kickback: fee_kickback,
        customer: {
          servicefees: servicefee ? 1 : 0,
        },
      });

      setTotal(pricing.total);
      setExpenses(pricing.expenses);
      setRevenue(pricing.revenue);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [typeId, price, fee_kickback, servicefee]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchPricing();
    }, 500);

    return () => clearTimeout(timeout);
  }, [fetchPricing]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (!total || !expenses || !revenue) return null;

  return (
    <div className={styles.pricingBox}>
      <div>
        <Text variant="h6" noMargin>
          Ticket buyer pays
        </Text>
        <Text variant="h4" noMargin>
          {formatPrice(total as number, account!.currency, user!.language)}
        </Text>
        <Text variant="small" noMargin>
          Incl. {vat}% VAT{" "}
        </Text>
      </div>
      <div>
        <Text variant="h6" noMargin>
          Service fee
        </Text>
        <Text variant="h4" noMargin>
          {formatPrice(expenses as number, account!.currency, user!.language)}
        </Text>
        <Text variant="small" noMargin>
          Incl. 21% VAT{" "}
        </Text>
      </div>
      <div>
        <Text variant="h6" noMargin>
          You receive
        </Text>
        <Text variant="h4" noMargin>
          {formatPrice(revenue as number, account!.currency, user!.language)}
        </Text>
        <Text variant="small" noMargin>
          Incl. {vat}% VAT{" "}
        </Text>
      </div>
    </div>
  );
};
interface TypeAttendeesProps {
  name: string;
  value: number;
  setFieldValue: (field: string, value: number) => void;
}

const TypeAttendees = (props: TypeAttendeesProps) => {
  const { name, value, setFieldValue } = props;

  const [moreThanTen, setMoreThanTen] = useState<boolean>(false);

  useEffect(() => {
    if (!moreThanTen && value > 10) {
      setFieldValue(name, 10);
    }
  }, [moreThanTen]);

  return (
    <div>
      {!moreThanTen && (
        <FormGroup>
          <FormLabel>Group ticket</FormLabel>
          <div className={styles.typeAttendees}>
            {[...Array(10)].map((_, i) => {
              return (
                <button
                  type="button"
                  key={i}
                  onClick={() => setFieldValue(name, i + 1)}
                  className={`
                    ${styles.attendee} 
                    ${value >= i + 1 ? styles.attendee__active : ""}`}
                  // style={{ backgroundColor: value >= i + 1 ? "red" : "white" }}
                  // active={value === i + 1}
                >
                  <RiUserLine />
                </button>
              );
            })}
          </div>
          <div className={styles.typeAttendees__info}>
            <Text variant="small">
              <b>{value === 1 ? "1 person." : `${value} persons.`}</b>{" "}
              <span onClick={() => setMoreThanTen(true)}>More than 10?</span>
            </Text>
          </div>
        </FormGroup>
      )}
      {moreThanTen && (
        <FormGroup>
          <FormLabel htmlFor="attendees">Group ticket</FormLabel>
          <FormInput name="attendees" type="number" min={1} max={25} step={1} />
          <div className={styles.typeAttendees__info}>
            <Text variant="small">
              <b>{value === 1 ? "1 person." : `${value} persons.`}</b>{" "}
              <span onClick={() => setMoreThanTen(false)}>Less than 10?</span>
            </Text>
          </div>
        </FormGroup>
      )}
    </div>
  );
};
