import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  UIPage,
} from "@eventsquare/uikit";

export const EditionSettingsLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[6];

  return (
    <>
      <UIPage>
        <PageHeader title="Settings" />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>
            <Link to="preferences">
              <MobileTopNavbarItem
                label="Preferences"
                active={path === "preferences"}
              />
            </Link>
            <Link to="layout">
              <MobileTopNavbarItem label="Layout" active={path === "layout"} />
            </Link>
            <Link to="messages">
              <MobileTopNavbarItem
                label="Messages"
                active={path === "messages"}
              />
            </Link>
            <Link to="scankeys">
              <MobileTopNavbarItem
                label="Scankeys"
                active={path === "scankeys"}
              />
            </Link>
            <Link to="secondary">
              <MobileTopNavbarItem
                label="Secondary"
                active={path === "secondary"}
              />
            </Link>
            <Link to="integrations">
              <MobileTopNavbarItem
                label="Integrations"
                active={path === "integrations"}
              />
            </Link>
            <Link to="dangerzone">
              <MobileTopNavbarItem
                label="Dangerzone"
                active={path === "dangerzone"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </>
  );
};
