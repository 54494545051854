import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  UIPage,
} from "@eventsquare/uikit";

export const EditionReportsLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[6];

  return (
    <>
      <UIPage>
        <PageHeader title="Reports" />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Dashboard"
                active={path === undefined}
              />
            </Link>
            <Link to="financial">
              <MobileTopNavbarItem
                label="Financial"
                active={path === "financial"}
              />
            </Link>
            <Link to="orders">
              <MobileTopNavbarItem label="Orders" active={path === "orders"} />
            </Link>
            <Link to="guestlist">
              <MobileTopNavbarItem
                label="Guestlist"
                active={path === "guestlist"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </>
  );
};
