import {
  Container,
  PageHeader,
  Text,
  UIPage,
  useAuth,
  PageLoader,
} from "@eventsquare/uikit";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useEditionContext } from "@context/editionContext";

import { Api } from "@lib/api";

import { EditionDashboardStats, EditionMetrics } from "@type/editions";

import { EditionDashboardGrid } from "@components/EditionDashboardGrid/EditionDashboardGrid";
import { EditionDashboardChart } from "@components/charts/EditionDashboardChart/EditionDashboardChart";
import { EditionDashboardMap } from "@components/EditionDashboardMap/EditionDashboardMap";

export const EditionDashboard = () => {
  const { editionId } = useParams();
  const { edition } = useEditionContext();
  const { accountId } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [stats, setStats] = useState<EditionDashboardStats | undefined>(
    undefined
  );
  const [metrics, setMetrics] = useState<EditionMetrics | undefined>(undefined);
  const [selectedStat, setSelectedStat] = useState<number | undefined>(
    undefined
  ); // index of the selected stat

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        start: edition.stats.start,
        end: edition.stats.end,
        filter: {
          edition: editionId,
        },
      };
      const statsApi = await Api.get(`/accounts/${accountId}/stats`, params);
      setStats(statsApi);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [accountId, edition.stats.end, edition.stats.start, editionId]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const fetchMetrics = useCallback(async () => {
    try {
      const response = await Api.get(`/editions/${editionId}/metrics`);
      setMetrics(response.metrics);
    } catch (error) {
      console.log(error);
    }
  }, [editionId]);

  useEffect(() => {
    fetchMetrics();
    const interval = setInterval(() => fetchMetrics(), 20000);
    return () => clearInterval(interval);
  }, [fetchMetrics]);

  return (
    <>
      <UIPage>
        <PageHeader title="Dashboard" />
        <Container>
          {loading && <PageLoader />}
          {!loading && (
            <>
              <EditionDashboardChart
                stats={stats}
                selectedStat={selectedStat}
                setSelectedStat={setSelectedStat}
              />
              <div>
                <Text variant="h4">Selected Stat Index : {selectedStat}</Text>
              </div>
              <EditionDashboardGrid
                stats={stats}
                metrics={metrics}
                selectedStat={selectedStat}
              />
              <div>
                <Text variant="h4">Where are your customers coming from?</Text>
                <Text variant="p">This map below shows a heatmap</Text>
              </div>
              <EditionDashboardMap />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
