import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import {
  BackButton,
  Container,
  MobileTopNavbar,
  MobileTopNavbarItem,
  PageHeader,
  PageLoader,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { useCallback, useEffect, useRef, useState } from "react";

import { Show } from "@type/shows";

import { Api } from "@lib/api";
import { formatDate, getShowName } from "@lib/helpers";

import { ShowContext, ShowContextInterface } from "@context/showContext";

export const EditionShowsLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[7];
  const { eventId, editionId, showId } = useParams();

  const { user } = useAuth();

  const pageRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState<Show | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchShow = useCallback(async () => {
    if (!showId) return;
    try {
      setLoading(true);
      const { show } = await Api.get(`/shows/${showId}`);
      setShow(show);
    } catch (error) {
      // TODO : handle error
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [showId]);

  useEffect(() => {
    fetchShow();
  }, [fetchShow]);

  const scrollUp = () => {
    if (!pageRef.current) return;
    pageRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!show) {
    return null;
  }

  return (
    <ShowContext.Provider
      value={
        { show, setShow, fetchShow, scrollUp } satisfies ShowContextInterface
      }
    >
      <UIPage ref={pageRef}>
        <PageHeader
          topAction={
            <Link to={`/events/${eventId}/editions/${editionId}/shows`}>
              <BackButton>Back to shows</BackButton>
            </Link>
          }
          title={getShowName(show, user!.language)}
          subtitle={
            show.name && show.name.trim() !== "" && show.date.start
              ? formatDate(
                  show.date.start,
                  user!.language,
                  !show.date.hide_hours
                )
              : undefined
          }
        />
        <Container>
          <MobileTopNavbar>
            <Link to="">
              <MobileTopNavbarItem
                label="Tickets"
                active={path === undefined}
              />
            </Link>
            <Link to="seatmap">
              <MobileTopNavbarItem
                label="Seatmap"
                active={path === "seatmap"}
              />
            </Link>
            <Link to="settings">
              <MobileTopNavbarItem
                label="Settings"
                active={path === "settings"}
              />
            </Link>
          </MobileTopNavbar>
        </Container>
        <Outlet />
      </UIPage>
    </ShowContext.Provider>
  );
};
