import { useState } from "react";
import { Button, Modal, ModalButtons, Text, useAuth } from "@eventsquare/uikit";
import { RiDeleteBinLine } from "@remixicon/react";
import { useNavigate, useParams } from "react-router-dom";

import { Api } from "@lib/api";
import { formatDate } from "@lib/helpers";

import { Show } from "@type/shows";
import { Language } from "@type/languages";

interface DeleteShowModalProps {
  show: Show | null;
  setShow: (show: Show | null) => void;
  setError: (message: string | string[] | undefined) => void;
}

export const DeleteShowModal = (props: DeleteShowModalProps) => {
  const { show, setShow, setError } = props;
  const navigate = useNavigate();
  const { eventId, editionId } = useParams();

  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const close = () => {
    if (loading) return;
    setShow(null);
  };

  const deleteShow = async () => {
    try {
      if (!show) return;
      setLoading(true);
      await Api.delete(`/shows/${show.id}`);
      navigate(`/events/${eventId}/editions/${editionId}/shows?removed=true`);
    } catch (error) {
      console.log(error);
      setError("We can not delete this show");
    } finally {
      setShow(null);
      setLoading(false);
    }
  };

  const getShowName = (show: Show | null, language: Language) => {
    if (!show) return "";

    if (show.name) return show.name;
    if (show.date.start)
      return (
        "show of " +
        formatDate(show!.date!.start, language, show.date.hide_hours)
      );
    return show.id;
  };

  return (
    <Modal
      isVisible={!!show}
      closeModal={close}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div>
        <Text variant="h3" color="error">
          Delete type
        </Text>
        <Text variant="lead">
          Are you sure you want to delete{" "}
          <b>{getShowName(show, user!.language)}</b>?<br />
          This action cannot be undone.
        </Text>
      </div>
      <ModalButtons>
        <Button block color="error" onClick={deleteShow} loading={loading}>
          Delete type
        </Button>
        <Button block variant="link" onClick={close} disabled={loading}>
          Cancel
        </Button>
      </ModalButtons>
    </Modal>
  );
};
