import styles from "./Pro.module.scss";
import { Button, ButtonGroup, Text } from "@eventsquare/uikit";
import { RiVipCrown2Line } from "@remixicon/react";

export const Pro = () => {
  return (
    <div className={styles.pro}>
      <div className={styles.pro__inner}>
        <RiVipCrown2Line />
        <Text variant="h4" noMargin>
          This feature is only available for <b>PRO accounts.</b>
        </Text>
        <Text variant="lead" textAlign="center">
          Upgrade today to unlock this feature and a plethora of other benefits
          available only with our PRO plan. Contact us for more information.
        </Text>
        <ButtonGroup noMargin>
          {/* TODO : Add link to chat Intercom */}
          <Button onClick={() => console.log("open chat")} variant="outline">
            Chat with us
          </Button>
          <a
            target="_blank"
            href="https://www.eventsquare.co/contact?t=organizer"
          >
            <Button>Contact us</Button>
          </a>
        </ButtonGroup>
      </div>
    </div>
  );
};
