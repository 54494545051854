import { Type } from "@type/types";

export const getTypeProgressBarColor = (type: Type) => {
  const {
    status,
    stock: { total, issued, pending },
  } = type;
  if (issued + pending > total) return "error";
  if (status === "soldout") return "info";
  if (status === "low") return "warning";
  if (status === "not_available") return "disabled";
  return "success";
};
