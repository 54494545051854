const localStorageName =
  import.meta.env.VITE_LOCAL_STORAGE_NAME ?? "_esq_admin";

export const getLocalStorage = (key: string): string | null => {
  if (!key) return null;
  const storage = localStorage.getItem(localStorageName);
  if (!storage) return null;
  const storageObj = JSON.parse(storage);
  return storageObj[key];
};

export const setLocalStorage = (
  key: string,
  value: string | number | object | boolean
): void => {
  const storage = localStorage.getItem(localStorageName);
  const storageObj = storage ? JSON.parse(storage) : {};
  storageObj[key] = value;
  localStorage.setItem("_esq_pos", JSON.stringify(storageObj));
};
