import styles from "./details.module.scss";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BackButton,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Message,
  PageHeader,
  PageLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  UIPage,
} from "@eventsquare/uikit";
import {
  RiEmotionSadLine,
  RiMailSendLine,
  RiTimerLine,
} from "@remixicon/react";

import { InvitationDetails } from "@type/invitations";

import { Api } from "@lib/api";

import { DeleteInvitationModal } from "@components/modals/DeleteInvitationModal/DeleteInvitationModal";

export const EditionInvitationDetails = () => {
  const { eventId, editionId, invitationId } = useParams();

  const [invitation, setInvitation] = useState<InvitationDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [resent, setResent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [invitationToDelete, setInvitationToDelete] = useState<
    string | undefined
  >(undefined);

  const getInvitation = useCallback(async () => {
    try {
      const { invitation } = await Api.get(`/invitations/${invitationId}`);
      return invitation;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [invitationId]);

  const fetchInvitation = async () => {
    setLoading(true);
    const invitation = await getInvitation();
    setInvitation(invitation);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvitation();
  }, []);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "failed":
      case "expired":
        return "error";
      case "accepted":
        return "success";
      case "created":
      case "processed":
      case "opened":
      default:
        return "info";
    }
  };

  const isExpired = (invitation: InvitationDetails) => {
    // TODO : is processed_at && no order && expiration date is passed
    return invitation.status === "expired";
  };

  const canBeSendAgain = (invitation: InvitationDetails) => {
    if (!invitation) return false;
    if (resent) return false;
    if (invitation.order) return false;
    if (isExpired(invitation)) return false;
    // Only invitations that are processed or opened can be resent
    if (invitation.status == "opened" || invitation.status == "processed") {
      return true;
    }
    return false;
  };

  const actionInvitation = async (type: "retry" | "resendinvitation") => {
    try {
      setSending(true);
      await Api.post(`/invitations/${invitationId}/actions`, {
        type: type,
      });

      if (type === "resendinvitation") {
        setResent(true);
        setSending(false);
      }

      if (type === "retry") {
        waitForInvitationToProcess();
      }
    } catch (error) {
      console.error(error);
      setSending(false);
    }
  };

  const waitForInvitationToProcess = async () => {
    setSending(true);
    try {
      const invitation = await getInvitation();
      if (!invitation || invitation.status === "created") {
        setTimeout(() => waitForInvitationToProcess(), 1000);
      } else {
        setInvitation(invitation);
        setSending(false);
      }
    } catch (error) {
      console.error(error);
      setSending(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!invitation) return null; // TODO: handle not found invitation

  // TODO: make sure time is readable for humans
  // TODO: action should be a component with same height as the Pageheader
  return (
    <UIPage>
      <Container>
        <PageHeader
          topAction={
            <Link to={`/events/${eventId}/editions/${editionId}/invitations`}>
              <BackButton>Go back</BackButton>
            </Link>
          }
        >
          {!invitation.reserved_at && (
            <ButtonGroup noMargin>
              <Button
                variant="outline"
                color="error"
                onClick={() => setInvitationToDelete(invitation.id)}
              >
                Delete invitation
              </Button>
            </ButtonGroup>
          )}
        </PageHeader>
        <div className={styles.invitation__header}>
          <div>
            <div className={styles.icon}>
              <RiMailSendLine size={80} />
            </div>
            <Text variant="h1">{invitation.reference}</Text>
            <Text variant="h3">
              {invitation.type}
              {" - "}
              <Badge
                content={invitation.status}
                color={getStatusColor(invitation.status)}
                size="small"
              />
            </Text>
            <Text variant="lead" noMargin>
              {invitation.user.firstname} {invitation.user.lastname} sent an
              invitation to {invitation.email} at {invitation.created_at}
            </Text>
          </div>
        </div>

        <div className={styles.invitation__content}>
          {invitation.status === "failed" && (
            <Message
              animate={false}
              title="Failed"
              message={"The invitation failed to send"}
              icon={<RiEmotionSadLine />}
            >
              <ButtonGroup noMargin>
                <Button
                  onClick={() => actionInvitation("retry")}
                  loading={sending}
                  disabled={sending}
                >
                  Retry
                </Button>
              </ButtonGroup>
            </Message>
          )}
          {invitation.order && (
            <Message
              animate={false}
              title="Order"
              icon={<RiMailSendLine />}
              message={`${invitation.order.reference} - ${invitation.order.status}`}
            >
              <ButtonGroup noMargin>
                <Link
                  to={`/events/${eventId}/editions/${editionId}/orders/${invitation.order.id}`}
                >
                  <Button>Open order</Button>
                </Link>
              </ButtonGroup>
            </Message>
          )}
          {isExpired(invitation) && (
            <Message
              animate={false}
              icon={<RiTimerLine />}
              type="error"
              title="Expired"
              message={`The invitation expired on ${invitation.expires_at}`}
            />
          )}
          {!resent && canBeSendAgain(invitation) && (
            <Message
              animate={false}
              title="Resend"
              message={"The invitation can be resent"}
              icon={<RiMailSendLine />}
            >
              <ButtonGroup noMargin>
                {" "}
                <Button
                  onClick={() => actionInvitation("resendinvitation")}
                  loading={sending}
                  disabled={sending}
                >
                  Resend
                </Button>
              </ButtonGroup>
            </Message>
          )}
          {resent && (
            <Message
              alert
              type="success"
              message={"The invitation has been resend"}
            />
          )}
          {invitation.types && invitation.types.length && (
            <>
              <Text variant="h4">Types</Text>
              <Table boxed>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Ticket</TableHeadCell>
                    <TableHeadCell>Quantity</TableHeadCell>
                    <TableHeadCell>Price</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invitation.types.map((type) => (
                    <TableRow key={type.id}>
                      <TableCell>{type.name}</TableCell>
                      <TableCell>{type.quantity}</TableCell>
                      <TableCell>
                        {invitation.type === "free" ? "Free" : type.price}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Text variant="h4">Details</Text>
          <Table boxed>
            <TableBody>
              <TableRow>
                <TableCell>Invitee</TableCell>
                <TableCell>{invitation.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reference</TableCell>
                <TableCell>{invitation.reference}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>{invitation.created_at}</TableCell>
              </TableRow>
              {invitation.failed_at && (
                <TableRow>
                  <TableCell>Failed at</TableCell>
                  <TableCell>{invitation.failed_at}</TableCell>
                </TableRow>
              )}
              {invitation.processed_at && (
                <TableRow>
                  <TableCell>Processed at</TableCell>
                  <TableCell>{invitation.processed_at}</TableCell>
                </TableRow>
              )}
              {invitation.expires_at && (
                <TableRow>
                  <TableCell>Expires at</TableCell>
                  <TableCell>{invitation.expires_at}</TableCell>
                </TableRow>
              )}
              {invitation.opened_at && (
                <TableRow>
                  <TableCell>Opened at</TableCell>
                  <TableCell>{invitation.opened_at}</TableCell>
                </TableRow>
              )}
              {invitation.reserved_at && (
                <TableRow>
                  <TableCell>Reserved at</TableCell>
                  <TableCell>{invitation.reserved_at}</TableCell>
                </TableRow>
              )}
              {invitation.order && (
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell>
                    <Link
                      to={`/events/${eventId}/editions/${editionId}/orders/${invitation.order.id}`}
                    >
                      {invitation.order.reference}
                    </Link>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Invited by</TableCell>
                <TableCell>
                  {invitation.user.firstname} {invitation.user.lastname}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Container>
      <DeleteInvitationModal
        invitationId={invitationToDelete}
        setInvitationId={setInvitationToDelete}
      />
    </UIPage>
  );
};
