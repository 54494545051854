import styles from "./questions.module.scss";
import {
  Button,
  Checkbox,
  Container,
  PageLoader,
  SortableTableBody,
  SortableTableBodyInner,
  SortableTableRow,
  SortableTableRowDrag,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  Toggle,
  useAuth,
} from "@eventsquare/uikit";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { RiPencilLine } from "@remixicon/react";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { Api } from "@lib/api";

import { Question, Questionable } from "@type/questions";
import { QuestionIcon } from "@components/QuestionIcon/QuestionIcon";

const Colgroup = () => {
  return (
    <colgroup className={styles.questionsColgroup}>
      <col className={styles.colName} />
      <col className={styles.colRequired} />
      <col className={styles.colOnTicket} />
      <col className={styles.colActive} />
      <col className={styles.colEdit} />
      <col className={styles.colDraghandler} />
    </colgroup>
  );
};

interface TypeQuestion {
  id: string;
  name: string;
  type: "date" | "text" | "select" | "checkbox";
  required: boolean;
  on_ticket: boolean;
  active: boolean;
  position: number;
}

export const EditionTypesQuestions = () => {
  const { eventId, editionId, ticketId } = useParams();
  const { accountId } = useAuth();

  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(true);
  const [questionables, setQuestionables] = useState<
    Questionable[] | undefined
  >(undefined);
  const [questionablesLoading, setQuestionablesLoading] =
    useState<boolean>(true);
  const [parsedQuestions, setParsedQuestions] = useState<TypeQuestion[]>([]);
  const [draggingQuestion, setDraggingQuestion] = useState<TypeQuestion | null>(
    null
  );

  const fetchQuestions = useCallback(async () => {
    if (!accountId) return;
    try {
      setQuestionsLoading(true);
      const { questions: accountQuestions } = await Api.get(
        `/accounts/${accountId}/questions`
      );
      setQuestions(accountQuestions);
    } catch (error) {
      console.error(error);
    } finally {
      setQuestionsLoading(false);
    }
  }, [accountId]);

  const fetchQuestionables = useCallback(async () => {
    if (!ticketId) return;
    try {
      setQuestionablesLoading(true);
      const { questions: typeQuestions } = await Api.get(
        `/types/${ticketId}/questions`
      );
      setQuestionables(typeQuestions);
    } catch (error) {
      console.error(error);
    } finally {
      setQuestionablesLoading(false);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchQuestions();
    fetchQuestionables();
  }, [fetchQuestions, fetchQuestionables]);

  useEffect(() => {
    if (!questions || !questionables) return;

    const parsed = questions
      // Merge the questions with the questionables
      .map((question, index) => {
        console.log("index", index);
        const questionable = questionables.find((q) => q.id === question.id);
        return {
          id: question.id,
          name: question.name,
          type: question.type,
          required: questionable?.required ?? false,
          on_ticket: questionable?.on_ticket ?? false,
          active: !!questionable,
          position: questionable?.position ?? null,
        };
      })
      // Sort the questions by position
      .sort((a, b) => {
        if (a.position === null && b.position === null) return 0;
        if (a.position === null) return 1;
        if (b.position === null) return -1;
        return a.position - b.position;
      })
      // Fill in the missing positions
      .map((question, index) => {
        return question.position === null
          ? { ...question, position: index + 1 }
          : question;
      });

    setParsedQuestions(parsed as TypeQuestion[]);
  }, [questions, questionables]);

  const updateSorting = async (sortedArr: TypeQuestion[]) => {
    try {
      await Api.put(`/types/${ticketId}/questions/sort`, {
        questions: sortedArr
          .filter((question) => question.active)
          .map((question) => question.id),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onDragStart = (e: DragStartEvent) => {
    const { active } = e;
    const q = parsedQuestions.find((q) => q.id === active.id);
    setDraggingQuestion(q ?? null);
  };
  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id && over?.id && active.id !== over?.id) {
      const oldIndex = parsedQuestions.findIndex((q) => q.id === active.id);
      const newIndex = parsedQuestions.findIndex((q) => q.id === over.id);
      const arr = arrayMove(parsedQuestions, oldIndex, newIndex);
      updateSorting(arr);
      setParsedQuestions(arr);
    }

    setDraggingQuestion(null);
  };
  const onDragCancel = () => {
    setDraggingQuestion(null);
  };

  const handleChange = async (
    questionId: string,
    type: "required" | "on_ticket"
  ) => {
    const oldParsed = [...parsedQuestions];
    const question = parsedQuestions.find((q) => q.id === questionId);
    if (!question) return;

    try {
      await Api.put(`/types/${ticketId}/questions/${questionId}`, {
        [type]: !question[type],
      });
      const newParsed = parsedQuestions.map((q) =>
        q.id === questionId ? { ...q, [type]: !q[type] } : q
      );
      updateSorting(newParsed);
      setParsedQuestions(newParsed);
    } catch (error) {
      console.error(error);
      setParsedQuestions(oldParsed);
    }
  };

  const handleToggleActive = async (questionId: string, checked: boolean) => {
    const oldParsed = [...parsedQuestions];
    try {
      if (checked) {
        await Api.post(`/types/${ticketId}/questions`, {
          question: questionId,
        });
      } else {
        await Api.delete(`/types/${ticketId}/questions/${questionId}`);
      }
      const newParsed = parsedQuestions.map((q) =>
        q.id === questionId ? { ...q, active: checked } : q
      );
      updateSorting(newParsed);
      setParsedQuestions(newParsed);
    } catch (error) {
      console.error(error);
      // Reset the active state of the question in the parsed array
      setParsedQuestions(oldParsed);
    }
  };

  return (
    <Container>
      {(questionsLoading || questionablesLoading) && <PageLoader />}
      {!questionsLoading && !questionablesLoading && (
        <>
          <div>
            <Text variant="h3">Custom questions</Text>
            <Text variant="lead">
              Add custom questions to this ticket type. These questions will be
              asked to the customer after completing his order before the
              tickets can be downloaded. You can find the answers to the custom
              questions in the tickets export at{" "}
              <Link to={`/events/${eventId}/editions/${editionId}/orders`}>
                your orders
              </Link>
              .
            </Text>
          </div>
          <Table scrollable>
            <Colgroup />
            <TableHead>
              <TableRow>
                <TableHeadCell>Question</TableHeadCell>
                <TableHeadCell>Required</TableHeadCell>
                <TableHeadCell>On ticket</TableHeadCell>
                <TableHeadCell>Active</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>
            <SortableTableBody
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragCancel={onDragCancel}
            >
              <SortableTableBodyInner items={parsedQuestions}>
                {parsedQuestions.map((question, index) => {
                  return (
                    <QuestionRow
                      key={index}
                      question={question}
                      index={index}
                      handleChange={handleChange}
                      handleToggleActive={handleToggleActive}
                    />
                  );
                })}
              </SortableTableBodyInner>
              <SortableTableRowDrag
                isDragging={!!draggingQuestion}
                colgroup={<Colgroup />}
              >
                {draggingQuestion && (
                  <>
                    <TableCell>
                      <div className={styles.question__name}>
                        <div className={styles.question__icon}>
                          <QuestionIcon type={draggingQuestion.type} />
                        </div>
                        <div className={styles.question__text}>
                          <div>
                            <strong>{draggingQuestion.name}</strong>
                          </div>
                          <Text variant="small" noMargin>
                            {draggingQuestion.type}
                          </Text>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.question__checkbox}>
                        <Checkbox
                          checked={
                            draggingQuestion.active
                              ? !!draggingQuestion.required
                              : false
                          }
                          onChange={() => {}}
                          disabled={!draggingQuestion.active}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.question__checkbox}>
                        <Checkbox
                          checked={
                            draggingQuestion.active
                              ? !!draggingQuestion.on_ticket
                              : false
                          }
                          onChange={() => {}}
                          disabled={!draggingQuestion.active}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.question__active}>
                        <Toggle
                          checked={draggingQuestion.active}
                          onChange={() => {}}
                          size="small"
                          disabled
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button size="small" variant="outline" icon>
                        <RiPencilLine />
                      </Button>
                    </TableCell>
                  </>
                )}
              </SortableTableRowDrag>
            </SortableTableBody>
          </Table>
        </>
      )}
      <div>
        <Link
          to={{
            pathname: `/settings/questions/create`,
            search: `?redirect=/events/${eventId}/editions/${editionId}/types/${ticketId}/questions`,
          }}
        >
          <Button>Create new question</Button>
        </Link>
      </div>
    </Container>
  );
};

interface QuestionRowProps {
  question: TypeQuestion;
  index: number;
  handleChange: (id: string, type: "required" | "on_ticket") => void;
  handleToggleActive: (id: string, checked: boolean) => void;
}

const QuestionRow = (props: QuestionRowProps) => {
  const { question, index, handleChange, handleToggleActive } = props;
  return (
    <SortableTableRow id={question.id} key={index} alignHandler="end">
      <TableCell>
        <div className={styles.question__name}>
          <div className={styles.question__icon}>
            <QuestionIcon type={question.type} />
          </div>
          <div className={styles.question__text}>
            <div>
              <strong>{question.name}</strong>
            </div>
            <Text variant="small" noMargin>
              {question.type}
            </Text>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.question__checkbox}>
          <Checkbox
            checked={question.active ? !!question.required : false}
            onChange={() => handleChange(question.id, "required")}
            disabled={!question.active}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.question__checkbox}>
          <Checkbox
            checked={question.active ? !!question.on_ticket : false}
            onChange={() => handleChange(question.id, "on_ticket")}
            disabled={!question.active}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.question__active}>
          <Toggle
            checked={question.active}
            onChange={() => handleToggleActive(question.id, !question.active)}
            size="small"
          />
        </div>
      </TableCell>
      <TableCell>
        <Link to={`/settings/questions/${question.id}`}>
          <Button size="small" variant="outline" icon>
            <RiPencilLine />
          </Button>
        </Link>
      </TableCell>
    </SortableTableRow>
  );
};
