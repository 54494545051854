import {
  EditionDashboardStats,
  EditionMetrics,
  StatsRevenue,
  StatsTickets,
} from "@type/editions";
import styles from "./EditionDashboardGrid.module.scss";
import { Count, Tile, useAuth } from "@eventsquare/uikit";
import { useCallback } from "react";
import {
  getCurrencySymbol,
  getDecimalSeparator,
  getThousandsSeparator,
} from "@lib/helpers";

interface EditionDashboardGridProps {
  stats?: EditionDashboardStats;
  metrics?: EditionMetrics;
  selectedStat?: number | undefined;
}

// This component is only used to display all the data in a grid
// All data comes from the parent component

export const EditionDashboardGrid = (props: EditionDashboardGridProps) => {
  const { stats, metrics, selectedStat } = props;
  const { account, user } = useAuth();

  const currencySymbol = useCallback(() => {
    if (!account) return "";
    return getCurrencySymbol(account.currency, user?.language);
  }, [account, user?.language]);

  const separator = useCallback(() => {
    if (!user) return "";
    return getThousandsSeparator(user.language);
  }, [user]);

  const decimal = useCallback(() => {
    if (!user) return "";
    return getDecimalSeparator(user.language);
  }, [user]);

  const getNumberFromStats = (
    key: string,
    selectedStat: number | undefined
  ): number => {
    const [a, b] = key.split(".");

    if (!stats) {
      return 0;
    }

    let value;
    if (typeof selectedStat !== "undefined") {
      if (!stats.data[selectedStat]) return 0;

      if (a === "tickets") {
        value = (stats!.data[selectedStat][a as "tickets"] as StatsTickets)[
          b as keyof StatsTickets
        ];
      } else {
        value = (stats!.data[selectedStat][a as "revenue"] as StatsRevenue)[
          b as keyof StatsRevenue
        ];
      }
    } else {
      if (a === "tickets") {
        value = (stats!.summary[a as "tickets"] as StatsTickets)[
          b as keyof StatsTickets
        ];
      } else {
        value = (stats!.summary[a as "revenue"] as StatsRevenue)[
          b as keyof StatsRevenue
        ];
      }
    }
    return value ? value : 0;
  };

  if (!stats || !metrics) {
    return null;
  }

  return (
    <>
      <div className={styles.dashboard}>
        <Tile
          title="Result"
          gridArea="result"
          value={
            <Count
              end={getNumberFromStats("revenue.result", selectedStat)}
              prefix={currencySymbol()}
              separator={separator()}
              decimal={decimal()}
              decimals={2}
            />
          }
          primary
        />
        <Tile
          title="Revenue"
          gridArea="revenue"
          value={
            <Count
              end={getNumberFromStats("revenue.revenue", selectedStat)}
              prefix={currencySymbol()}
              separator={separator()}
              decimal={decimal()}
              decimals={2}
            />
          }
        />
        <Tile
          title="Refunds"
          gridArea="refunds"
          value={
            <Count
              end={getNumberFromStats("revenue.refund_amount", selectedStat)}
              prefix={currencySymbol()}
              separator={separator()}
              decimal={decimal()}
              decimals={2}
            />
          }
        />
        <Tile
          title="Tickets"
          gridArea="tickets"
          value={
            <Count end={getNumberFromStats("tickets.tickets", selectedStat)} />
          }
        >
          <TileSmallMetrics
            paid={getNumberFromStats("tickets.tickets_paid", selectedStat)}
            free={getNumberFromStats("tickets.tickets_free", selectedStat)}
          />
        </Tile>
        <Tile
          title="Vouchers"
          gridArea="vouchers"
          value={
            <Count end={getNumberFromStats("tickets.vouchers", selectedStat)} />
          }
        >
          <TileSmallMetrics
            paid={getNumberFromStats("tickets.vouchers_paid", selectedStat)}
            free={getNumberFromStats("tickets.vouchers_free", selectedStat)}
          />
        </Tile>
        <Tile
          title="Total"
          gridArea="total"
          value={
            <Count end={getNumberFromStats("revenue.orders", selectedStat)} />
          }
        />
        <Tile
          title="Attendees"
          gridArea="attendees"
          value={
            <Count
              end={getNumberFromStats("tickets.attendees", selectedStat)}
            />
          }
        />
        <Tile
          title="Customers"
          gridArea="customers"
          value={
            <Count
              end={getNumberFromStats("revenue.customers", selectedStat)}
            />
          }
        />
        <Tile
          title="Shopping"
          gridArea="shopping"
          value={<Count end={metrics?.active_carts ?? 0} />}
          pulse={!!(metrics?.active_carts && metrics.active_carts > 0)}
        />
      </div>
    </>
  );
};

const TileSmallMetrics = (props: { paid: number; free: number }) => {
  return (
    <div className={styles.smallMetrics}>
      <div>
        <h6 className={styles.smallMetrics__label}>
          Paid <span className={styles.smallMetrics__indicator_paid} />
        </h6>
        <div className={styles.smallMetrics__value}>
          <Count end={props.paid} />
        </div>
      </div>
      <div>
        <h6 className={styles.smallMetrics__label}>
          Free
          <span className={styles.smallMetrics__indicator_free} />
        </h6>
        <div className={styles.smallMetrics__value}>
          <Count end={props.free} />
        </div>
      </div>
    </div>
  );
};
