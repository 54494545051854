import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  Empty,
  EventCard,
  EventGrid,
  PageHeader,
  PageLoader,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { FilterBar, FilterSelect } from "@eventsquare/uikit/filters";
import { useCallback, useEffect, useState } from "react";
import { RiStarLine } from "@remixicon/react";

import { Api } from "@lib/api";

interface Event {
  branding: {
    background: {
      color: string;
      image: string | null;
    };
    banner: string | null;
    color: string | null;
    logo: string | null;
  };
  created_at: string;
  dates: {
    start: string | null;
    end: string | null;
  };
  editions: EventEdition[];
  id: string;
  name: string;
  status: {
    published: boolean;
    live: boolean;
  };
  type: EventTypeType;
  updated_at: string;
}

interface EventEdition {
  branding: {
    background: {
      color: string;
      image: string | null;
    };
    banner: string | null;
    color: string | null;
    logo: string | null;
  };
  created_at: string;
  id: string;
  name: string | null;
  updated_at: string;
}

type EventTypeType =
  | "charity"
  | "comedy"
  | "concert"
  | "conference"
  | "culinary"
  | "dance"
  | "education"
  | "exhibition"
  | "fair"
  | "festival"
  | "film"
  | "health"
  | "lan-party"
  | "leisure"
  | "market"
  | "meeting"
  | "musical"
  | "other"
  | "outdoor"
  | "party"
  | "seminar"
  | "show"
  | "theater"
  | "tournament"
  | "travelling"
  | "workshop";

type Sorting = "alphabetically" | "creation_date" | "event_date" | "status";

export const Events = () => {
  const { accountId } = useAuth();
  const [sorting, setSorting] = useState<Sorting>("alphabetically");
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [eventsTotal, setEventsTotal] = useState<number | undefined>(undefined);

  const fetchEvents = useCallback(async () => {
    setLoadingEvents(true);
    try {
      const { events, total } = await Api.get(`/accounts/${accountId}/events`);
      setEvents(events);
      setEventsTotal(total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEvents(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const sortEvents = (eventsArr: Event[], sortingMethod: Sorting): Event[] => {
    switch (sortingMethod) {
      case "alphabetically":
        return eventsArr.sort((a, b) =>
          a.name.trim() < b.name.trim() ? -1 : 1
        );
      case "creation_date":
        return eventsArr.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      case "event_date": {
        const noDates = eventsArr
          .filter((event) => !event.dates.start)
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
        const futureDates = eventsArr
          .filter(
            (event) =>
              !!event.dates.start && new Date(event.dates.start) > new Date()
          )
          .sort((a, b) => (a.dates.start! < b.dates.start! ? -1 : 1));
        const pastDates = eventsArr
          .filter(
            (event) =>
              !!event.dates.start && new Date(event.dates.start) < new Date()
          )
          .sort((a, b) => (a.dates.start! > b.dates.start! ? -1 : 1));

        return [...futureDates, ...pastDates, ...noDates];
      }
      case "status": {
        const liveStores = eventsArr
          .filter((event) => event.status.live === true)
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });
        const openedStores = eventsArr
          .filter(
            (event) =>
              event.status.published === true && event.status.live === false
          )
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });
        const closedStores = eventsArr
          .filter(
            (event) =>
              event.status.published === false && event.status.live === false
          )
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });

        return [...liveStores, ...openedStores, ...closedStores];
      }
      default:
        return eventsArr;
    }
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Events" badge={eventsTotal ?? undefined}>
          <ButtonGroup noMargin>
            <Link to="create">
              <Button variant="outline">Create event</Button>
            </Link>
          </ButtonGroup>
        </PageHeader>

        <Container>
          <FilterBar fixed>
            <FilterSelect
              sort
              value={sorting}
              onChange={(sort: string) => setSorting(sort as Sorting)}
              options={[
                {
                  value: "event_date",
                  title: "Date",
                },
                {
                  value: "creation_date",
                  title: "Creation date",
                },
                {
                  value: "alphabetically",
                  title: "Name",
                },
                {
                  value: "status",
                  title: "Status",
                },
              ]}
            />
          </FilterBar>
          {loadingEvents && <PageLoader />}
          {!loadingEvents && events.length === 0 && (
            <Empty
              title="No events yet"
              lead="Create your first event by clicking the button above"
              icon={<RiStarLine />}
            />
          )}
          {!loadingEvents && events.length > 0 && (
            <EventGrid>
              {sortEvents(events, sorting).map((event, index) => {
                return (
                  <Link
                    to={
                      event.editions.length === 1
                        ? `/events/${event.id}/editions/${event.editions[0].id}`
                        : `/events/${event.id}`
                    }
                    key={event.id}
                  >
                    <EventCard
                      title={event.name}
                      subtitle={
                        event.editions.length === 1
                          ? "First edition"
                          : event.editions.length + " editions"
                      }
                      banner={event.editions[0]?.branding?.banner ?? undefined}
                      logo={event.branding.logo ?? undefined}
                      color={event.editions[0]?.branding.color ?? "#068CFC"}
                      backgroundColor={event.branding.background.color}
                      type={event.type}
                      status={
                        event.status.live
                          ? "live"
                          : event.status.published
                          ? "open"
                          : "closed"
                      }
                      popIn
                      index={index}
                    />
                  </Link>
                );
              })}
            </EventGrid>
          )}
        </Container>
      </UIPage>
    </>
  );
};
