import { useTheme } from "@eventsquare/uikit";
import {
  ChartData,
  ChartDataset,
  ChartEvent,
  Chart as ChartJS,
  Interaction,
  registerables,
  ScriptableContext,
  TimeSeriesScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import "chartjs-adapter-luxon";

import { EditionDashboardStats } from "@type/editions";

import { getOptions } from "../chartOptions";

ChartJS.register(...registerables, TimeSeriesScale);

interface EditionDashboardCartProps {
  stats?: EditionDashboardStats;
  selectedStat: number | undefined;
  setSelectedStat: Dispatch<SetStateAction<number | undefined>>;
}

export const EditionDashboardChart = (props: EditionDashboardCartProps) => {
  const { stats, selectedStat, setSelectedStat } = props;
  const { colorMode } = useTheme();
  const chartRef = useRef();

  const [chartData, setChartData] = useState<ChartData<
    "bar",
    { x: string; y: number }[],
    string
  > | null>(null);

  const updateChartData = useCallback(() => {
    if (!stats) return;

    const datasets: ChartDataset<"bar", { x: string; y: number }[]>[] = [
      {
        backgroundColor: (ctx: { dataIndex: number }) => {
          return !selectedStat ||
            (selectedStat && selectedStat == ctx.dataIndex)
            ? "rgba(6,140,252,1)"
            : "rgba(6,140,252,.5)";
        },
        barPercentage: 0.5,
        borderRadius: (ctx: ScriptableContext<"bar">) => {
          const datapoint = ctx.dataIndex;
          const val = ctx.chart.data.datasets[0].data[datapoint] as
            | null
            | undefined
            | { y: number };
          if (val == undefined || val == null || val.y == 0) {
            return {
              topLeft: 12,
              topRight: 12,
              bottomLeft: 12,
              bottomRight: 12,
            };
          } else {
            return {
              topLeft: 0,
              topRight: 0,
              bottomLeft: 12,
              bottomRight: 12,
            };
          }
        },
        barThickness: 7,
        borderSkipped: false,
        borderWidth: 0,
        data: stats.data.map((stat) => {
          return {
            x: stat.date,
            y: stat.tickets.tickets_paid,
          };
        }),
        grouped: false,
        maxBarThickness: 12,
      },
      {
        backgroundColor: (ctx: { dataIndex: number }) => {
          return !selectedStat ||
            (selectedStat && selectedStat == ctx.dataIndex)
            ? "rgba(6,140,252,.6)"
            : "rgba(6,140,252,.2)";
        },
        barPercentage: 0.5,
        borderRadius: (ctx: ScriptableContext<"bar">) => {
          const datapoint = ctx.dataIndex;
          const val = ctx.chart.data.datasets[0].data[datapoint] as
            | null
            | undefined
            | { y: number };
          if (val == undefined || val == null || val.y == 0) {
            return {
              topLeft: 12,
              topRight: 12,
              bottomLeft: 12,
              bottomRight: 12,
            };
          } else {
            return {
              topLeft: 12,
              topRight: 12,
              bottomLeft: 0,
              bottomRight: 0,
            };
          }
        },
        barThickness: 7,
        borderSkipped: false,
        borderWidth: 0,
        data: stats.data.map((stat) => {
          return {
            x: stat.date,
            y: stat.tickets.tickets_free,
          };
        }),
        grouped: false,
        maxBarThickness: 12,
      },
    ];

    setChartData({
      labels: [],
      datasets: datasets,
    });
  }, [stats, selectedStat]);

  useEffect(() => {
    updateChartData();
  }, [updateChartData]);

  const highlighter = {
    id: "higlighter",
    beforeDraw: (chart: ChartJS<"bar">, arg: unknown, options: unknown) => {
      // TODO : DELETE THIS
      if (arg === undefined) {
        console.log("beforeDraw", chart, arg, options);
      }

      if (!stats || typeof selectedStat === "undefined") return;

      const {
        ctx,
        chartArea: {
          top,
          // bottom,
          left,
          right,
          // width,
          height,
        },
        scales: {
          x,
          // y
        },
      } = chart;

      if (top === 180) {
        console.log("area", top, left, right, height, x);
      }

      ctx.save();

      // const dateValue = stats?.data[selectedStat].date;

      // // get start 6 months before dateValue and end 6 months after dateValue
      // let startDate; //= DateTime.fromISO(dateValue).minus({ months: 6 });
      // let endDate; //= DateTime.fromISO(dateValue).plus({ months: 6 });

      // switch (stats.mode) {
      //   case "hour":
      //     startDate = DateTime.fromISO(dateValue).minus({ minutes: 30 });
      //     endDate = DateTime.fromISO(dateValue).plus({ minutes: 30 });
      //     break;
      //   case "day":
      //   default:
      //     startDate = DateTime.fromISO(dateValue).minus({ hours: 12 });
      //     endDate = DateTime.fromISO(dateValue).plus({ hours: 12 });
      //     break;
      //   case "week":
      //     startDate = DateTime.fromISO(dateValue).minus({ days: 3 });
      //     endDate = DateTime.fromISO(dateValue).plus({ days: 3 });
      //     break;
      //   case "month":
      //     startDate = DateTime.fromISO(dateValue).minus({ days: 15 });
      //     endDate = DateTime.fromISO(dateValue).plus({ days: 15 });
      //     break;
      //   case "quarter":
      //     startDate = DateTime.fromISO(dateValue).minus({ days: 45 });
      //     endDate = DateTime.fromISO(dateValue).plus({ days: 45 });
      //     break;
      //   case "year":
      //     startDate = DateTime.fromISO(dateValue).minus({ months: 6 });
      //     endDate = DateTime.fromISO(dateValue).plus({ months: 6 });
      //     break;
      // }
      //ctx.fillStyle = "rgba(30,56,80,.8)";
      ctx.fillStyle = "#1e3850";

      // const rectStartX = x.getPixelForValue(startDate.toISO());
      // const rectEndX = x.getPixelForValue(startDate.toISO());

      // console.log(rectStartX, rectEndX);

      // let startWidthDiff = 0;
      // let endWidthDiff = 0;
      // if (left > rectStartX) {
      //   startWidthDiff = left - rectStartX;
      // }
      // if (right < rectEndX) {
      //   endWidthDiff = rectEndX - right;
      // }

      // ctx.fillRect(
      //   Math.max(left, rectStartX),
      //   top,
      //   rectEndX - rectStartX - startWidthDiff - endWidthDiff,
      //   height
      // );
      ctx.restore();
    },
  };

  if (!chartData) return null;

  return (
    <div
      style={{
        marginBottom: "2rem",
        position: "relative",
        height: "0",
        width: "100%",
        paddingBottom: "400px",
      }}
    >
      <Bar
        ref={chartRef}
        data={chartData!}
        options={{
          ...getOptions(colorMode as "light" | "dark"),
        }}
        redraw={false}
        style={{
          position: "absolute",
        }}
        plugins={[highlighter]}
        onClick={(event: React.MouseEvent<HTMLCanvasElement>) => {
          if (!chartRef.current) return;

          const nearestElement = Interaction.modes.index(
            chartRef.current,
            event as unknown as ChartEvent,
            { intersect: false }
          );

          if (nearestElement && nearestElement[0]) {
            if (
              typeof selectedStat != "undefined" &&
              selectedStat == nearestElement[0].index
            ) {
              setSelectedStat(undefined);
            } else {
              setSelectedStat(nearestElement[0].index);
            }
          } else {
            setSelectedStat(undefined);
          }
        }}
      />
    </div>
  );
};
