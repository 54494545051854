import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Empty,
  Message,
  PageHeader,
  PageLoader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  UIPage,
  useAuth,
} from "@eventsquare/uikit";
import { FilterSearch } from "@eventsquare/uikit/filters";
import { RiMailSendLine } from "@remixicon/react";
import { useCallback, useEffect, useState } from "react";

import { Api } from "@lib/api";
import { formatDate, stopPropagation } from "@lib/helpers";

import { Invitation, InvitationStatus } from "@type/invitations";

const INVITATIONS_ON_PAGE = 25;

export const EditionInvitations = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { eventId, editionId } = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [invitationsTotal, setInvitationsTotal] = useState<number | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState("");
  const [invitationRemoved, setInvitationRemoved] = useState<boolean>(false);
  const [invitationCreated, setInvitationCreated] = useState<boolean>(false);

  useEffect(() => {
    const removed = searchParams.get("removed");
    if (removed && removed === "true") {
      setInvitationRemoved(true);
    }
    const saved = searchParams.get("saved");
    if (saved && saved === "true") {
      setInvitationCreated(true);
    }
  }, []);

  const fetchInvitations = useCallback(async () => {
    const activePage = searchParams.get("page")
      ? parseInt(searchParams.get("page")!)
      : 1;
    const search = searchParams.get("search") ?? "";
    try {
      setLoading(true);

      const params: {
        limit: number;
        offset: number;
        search?: string;
        // payment?: string[];
        // hide_failed?: boolean;
      } = {
        limit: INVITATIONS_ON_PAGE,
        offset: (activePage - 1) * INVITATIONS_ON_PAGE,
      };

      if (search) {
        params["search"] = search;
      }
      const { invitations, meta } = await Api.get(
        `/editions/${editionId}/invitations`,
        params
      );
      setInvitations(invitations);
      setInvitationsTotal(meta.pagination.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [editionId, searchParams]);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentParams = new URLSearchParams(searchParams);
      if (searchValue === "") {
        currentParams.delete("search");
        currentParams.delete("page");
        setSearchParams(currentParams);
        return;
      }
      currentParams.set("search", searchValue);
      currentParams.delete("page");
      setSearchParams(currentParams);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  const getStatusBadge = (status: InvitationStatus) => {
    switch (status) {
      case "accepted":
        return "success";
      case "failed":
      case "expired":
        return "error";
      case "created":
      case "processed":
      case "opened":
      default:
        return "disabled";
    }
  };

  return (
    <>
      <UIPage>
        <PageHeader title="Invitations">
          <ButtonGroup noMargin>
            <FilterSearch value={searchValue} setValue={setSearchValue} />
            <Link to="create">
              <Button variant="outline">New invitation</Button>
            </Link>
            {/* <FilterToggle filterActive={} /> */}
          </ButtonGroup>
        </PageHeader>

        <Container>
          {loading && <PageLoader />}
          {!loading && invitationRemoved && (
            <Message
              alert
              type="info"
              message={"invitation_has_been_removed"}
              onClick={() => setInvitationRemoved(false)}
            />
          )}
          {!loading && invitationCreated && (
            <Message
              alert
              type="success"
              message={"invitation_has_been_created"}
              onClick={() => setInvitationCreated(false)}
            />
          )}
          {!loading && invitations && invitations.length === 0 && (
            <Empty
              title={"No invitations found"}
              lead={"No invitations found"}
              icon={<RiMailSendLine />}
            />
          )}
          {!loading && invitations && invitations.length > 0 && (
            <>
              <Table scrollable clickable>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Reference</TableHeadCell>
                    <TableHeadCell>Status</TableHeadCell>
                    <TableHeadCell>Source</TableHeadCell>
                    <TableHeadCell>Created</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invitations.map((invitation) => {
                    const createdAt = formatDate(
                      invitation.created_at,
                      user!.language,
                      true
                    );
                    const invitationUrl = `/events/${eventId}/editions/${editionId}/invitations/${invitation.id}`;
                    return (
                      <TableRow
                        key={invitation.id}
                        onClick={() => navigate(invitationUrl)}
                      >
                        <TableCell>
                          <div>
                            <Link to={invitationUrl} onClick={stopPropagation}>
                              <strong>{invitation.reference}</strong>
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Badge
                            size="small"
                            content={invitation.status}
                            color={getStatusBadge(invitation.status)}
                          />
                        </TableCell>
                        <TableCell>{invitation.type}</TableCell>
                        <TableCell>
                          {createdAt.charAt(0).toUpperCase() +
                            createdAt.slice(1)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                activePage={
                  searchParams.get("page")
                    ? parseInt(searchParams.get("page")!)
                    : 1
                }
                itemsTotal={invitationsTotal ?? 0}
                itemsPerPage={INVITATIONS_ON_PAGE}
                setActivePage={(page: number) => {
                  const currentParams = new URLSearchParams(searchParams);
                  if (page === 1) {
                    currentParams.delete("page");
                    setSearchParams(currentParams);
                    return;
                  }
                  currentParams.set("page", page.toString());
                  setSearchParams(currentParams);
                  return;
                }}
                translations={{
                  first_page: "First",
                  last_page: "Last",
                  active_page: "page {{activePage}} from {{totalPages}}",
                }}
              />
            </>
          )}
        </Container>
      </UIPage>
    </>
  );
};
