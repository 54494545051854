import { Container, Count, Tile, UIPage } from "@eventsquare/uikit";
import { createSearchParams, useParams } from "react-router-dom";

import { useTypeContext } from "@context/typeContext";

import { Dashboard } from "@components/DashBoard/Dashboard";
import { getTypeProgressBarColor } from "@lib/tickets";

export const EditionTypesDashboard = () => {
  const { type } = useTypeContext();
  const { eventId, editionId } = useParams();

  if (!type) return null;

  return (
    <>
      <UIPage>
        <Container>
          <Dashboard
            barColor={getTypeProgressBarColor(type)}
            issued={type.stock.issued}
            pending={type.stock.pending}
            total={type.stock.total}
            guestlistLink={`/events/${eventId}/editions/${editionId}/reports/guestlist?${createSearchParams(
              {
                tickets: [type.id],
              }
            )}`}
            title="Tickets issued"
          >
            <Tile title="Pending" value={<Count end={type.stock.pending} />} />
            <Tile
              title="Available"
              value={<Count end={type.stock.available} />}
            />
            <Tile title="Total" value={<Count end={type.stock.total} />} />
          </Dashboard>
        </Container>
      </UIPage>
    </>
  );
};
