import { Outlet } from "react-router-dom";

import { UIContentMain, UIContentTopbar, Topbar } from "@eventsquare/uikit";

export const TopLayout = () => {
  return (
    <>
      <UIContentTopbar>
        <Topbar hideTablet />
      </UIContentTopbar>

      <UIContentMain>
        <Outlet />
      </UIContentMain>
    </>
  );
};
