import styles from "./PageLoader.module.scss";
import { Loader } from "@eventsquare/uikit";

// TODO : Move this component to the uikit
// TODO : Also for POS

export const PageLoader = () => {
  return (
    <div className={styles.pageLoader}>
      <Loader size="large" />
    </div>
  );
};
