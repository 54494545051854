import { Button, Modal, ModalButtons, Text } from "@eventsquare/uikit";
import { useState } from "react";
import { RiDeleteBinLine } from "@remixicon/react";
import { useNavigate, useParams } from "react-router-dom";

import { Api } from "@lib/api";

import { TypeDetails } from "@type/types";

interface DeleteTypeModalProps {
  type: TypeDetails | undefined;
  setType: (type: TypeDetails | undefined) => void;
  setError: (message: string | string[] | undefined) => void;
}

export const DeleteTypeModal = (props: DeleteTypeModalProps) => {
  const { type, setType, setError } = props;
  const navigate = useNavigate();
  const { eventId, editionId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);

  const close = () => {
    setType(undefined);
  };

  const deleteType = async () => {
    if (!type) return;
    setLoading(true);
    try {
      await Api.delete(`/types/${type.id}`);
      navigate(`/events/${eventId}/editions/${editionId}/tickets?removed=true`);
    } catch (error) {
      // TODO : handle error
      console.log(error);
      setError("cannot_delete_type");
    } finally {
      close();
      setLoading(false);
    }
  };

  return (
    <Modal
      isVisible={!!type}
      closeModal={close}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div>
        <Text variant="h3" color="error">
          Delete type
        </Text>
        <Text variant="lead">
          Are you sure you want to delete <b>{type?.name}</b>?<br />
          This action cannot be undone.
        </Text>
      </div>
      <ModalButtons>
        <Button block color="error" onClick={deleteType} loading={loading}>
          Delete type
        </Button>
        <Button block variant="link" onClick={close} disabled={loading}>
          Cancel
        </Button>
      </ModalButtons>
    </Modal>
  );
};
